import React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import dayjs from 'dayjs';
import { IConsultation } from '../../../service/Consultation';

interface PatientHistoryProps {
  open: boolean;
  onClose: () => void;
  history: IConsultation[];
  loading: boolean;
}

const PatientHistory: React.FC<PatientHistoryProps> = ({ open, onClose, history, loading }) => {
  const [selectedConsultation, setSelectedConsultation] = React.useState<IConsultation | null>(null);

  const handleViewDetails = (consultation: IConsultation) => {
    setSelectedConsultation(consultation);
  };

  const handleBack = () => {
    setSelectedConsultation(null);
  };

  const categorizeAppointments = (appointments: IConsultation[]) => {
    const now = dayjs();
    const last7Days: IConsultation[] = [];
    const last30Days: IConsultation[] = [];
    const olderAppointments: IConsultation[] = [];

    appointments.forEach((appointment) => {
      const appointmentDate = dayjs(appointment.appointmentDate);
      const diffInDays = now.diff(appointmentDate, 'day');

      if (diffInDays <= 7) {
        last7Days.push(appointment);
      } else if (diffInDays <= 30) {
        last30Days.push(appointment);
      } else {
        olderAppointments.push(appointment);
      }
    });

    return { last7Days, last30Days, olderAppointments };
  };

  const { last7Days, last30Days, olderAppointments } = categorizeAppointments(history);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="patient-history-modal"
        sx={{
          borderRadius: 'md',
          boxShadow: 'lg',
          minWidth: selectedConsultation ? 800 : 500,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <ModalClose />
        <Typography id="patient-history-modal" level="h4" component="h2" mb={2}>
          {selectedConsultation ? 'Consultation Details' : 'Patient History'}
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : selectedConsultation ? (
          <Box sx={{ overflowY: 'auto', maxHeight: '75vh', pr: 2 }}>
            <Button variant="outlined" onClick={handleBack} sx={{ mb: 2 }}>
              Back to History
            </Button>

            {/* Basic Information */}
            <Box sx={{ border: '1px solid #ccc', borderRadius: 'md', mb: 3, p: 2 }}>
              <Typography level="h4" component="h3" mb={2}>
                Basic Information
              </Typography>
              <Table>
                <tbody>
                  <tr><td><strong>Appointment Date:</strong></td><td>{selectedConsultation.appointmentDate}</td></tr>
                  <tr><td><strong>Token Number:</strong></td><td>{selectedConsultation.tokenNumber || 'N/A'}</td></tr>
                  <tr><td><strong>Template:</strong></td><td>{selectedConsultation.template?.name || 'N/A'}</td></tr>
                  <tr><td><strong>Diagnosis:</strong></td><td>{selectedConsultation.diagnoseName || 'N/A'}</td></tr>
                  <tr><td><strong>Drugs:</strong></td><td>{selectedConsultation.drugs?.map(d => d.tradeName).join(', ') || 'N/A'}</td></tr>
                  <tr><td><strong>Fees:</strong></td><td>{selectedConsultation.fees}</td></tr>
                  <tr><td><strong>Payment Mode:</strong></td><td>{selectedConsultation.paymentMode}</td></tr>
                  <tr><td><strong>Next Visit Date:</strong></td><td>{selectedConsultation.nextVisitDate || 'N/A'}</td></tr>
                  <tr><td><strong>Advices:</strong></td><td>{selectedConsultation.advices || 'N/A'}</td></tr>
                  <tr><td><strong>Remarks:</strong></td><td>{selectedConsultation.remarks || 'N/A'}</td></tr>
                </tbody>
              </Table>
            </Box>

            {/* Clinical Findings */}
            <Box sx={{ border: '1px solid #ccc', borderRadius: 'md', mb: 3, p: 2 }}>
              <Typography level="h4" component="h3" mb={2}>
                Clinical Findings
              </Typography>
              <Table>
                <tbody>
                  <tr><td><strong>General Information:</strong></td><td>{selectedConsultation.generalInformation || 'N/A'}</td></tr>
                  <tr><td><strong>RS:</strong></td><td>{selectedConsultation.rs || 'N/A'}</td></tr>
                  <tr><td><strong>CVS:</strong></td><td>{selectedConsultation.cvs || 'N/A'}</td></tr>
                  <tr><td><strong>Abdomen:</strong></td><td>{selectedConsultation.abdomen || 'N/A'}</td></tr>
                  <tr><td><strong>CNS:</strong></td><td>{selectedConsultation.cns || 'N/A'}</td></tr>
                </tbody>
              </Table>
            </Box>

            {/* Vital Information */}
            <Box sx={{ border: '1px solid #ccc', borderRadius: 'md', mb: 3, p: 2 }}>
              <Typography level="h4" component="h3" mb={2}>
                Vital Information
              </Typography>
              <Table>
                <tbody>
                  <tr><td><strong>Height:</strong></td><td>{selectedConsultation.vitalInformation.height}</td></tr>
                  <tr><td><strong>Weight:</strong></td><td>{selectedConsultation.vitalInformation.weight}</td></tr>
                  <tr><td><strong>Temperature:</strong></td><td>{`${selectedConsultation.vitalInformation.temperatureValue} ${selectedConsultation.vitalInformation.temperatureUnit}`}</td></tr>
                  <tr><td><strong>Heart Rate:</strong></td><td>{selectedConsultation.vitalInformation.heartRate}</td></tr>
                  <tr><td><strong>Oxygen Saturation:</strong></td><td>{selectedConsultation.vitalInformation.oxygenSaturation}</td></tr>
                  <tr><td><strong>Respiratory Rate:</strong></td><td>{selectedConsultation.vitalInformation.respiratoryRate}</td></tr>
                  <tr><td><strong>BP:</strong></td><td>{selectedConsultation.vitalInformation.bp}</td></tr>
                  <tr><td><strong>CRT:</strong></td><td>{selectedConsultation.vitalInformation.crt}</td></tr>
                  <tr><td><strong>Other Complaints:</strong></td><td>{selectedConsultation.vitalInformation.otherComplaints || 'N/A'}</td></tr>
                </tbody>
              </Table>
            </Box>

            {/* Complaint Details */}
            <Box sx={{ border: '1px solid #ccc', borderRadius: 'md', mb: 3, p: 2 }}>
              <Typography level="h4" component="h3" mb={2}>
                Complaint Details
              </Typography>
              <Table aria-labelledby="complaint-details">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Duration (Days)</th>
                    <th>Additional Info</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedConsultation.complaints.map((complaint, index) => (
                    <tr key={index}>
                      <td>{complaint.type}</td>
                      <td>{complaint.duration}</td>
                      <td>{complaint.additionalInfoValues || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>

            {/* Template and Drugs */}
            <Box sx={{ border: '1px solid #ccc', borderRadius: 'md', mb: 3, p: 2 }}>
              <Typography level="h4" component="h3" mb={2}>
                Template and Drugs
              </Typography>
              
              {/* Template Information */}
              <Table>
                <tbody>
                  <tr><td><strong>Template Name:</strong></td><td>{selectedConsultation.template?.name || 'N/A'}</td></tr>
                  <tr><td><strong>Diagnosis:</strong></td><td>{selectedConsultation.template?.diagnosis || 'N/A'}</td></tr>
                </tbody>
              </Table>

              {/* Drugs Table */}
              <Typography level="h4" component="h4" mt={3} mb={2}>
                Drugs
              </Typography>
              <Table aria-labelledby="drugs-table">
                <thead>
                  <tr>
                    <th style={{ width: 200, padding: '12px 6px' }}>Drug Info</th>              
                    <th style={{ width: 100, padding: '12px 6px' }}>Dosage</th>
                    <th style={{ width: 120, padding: '12px 6px' }}>Dosage Unit</th>
                    <th style={{ width: 120, padding: '12px 6px' }}>Frequency</th>
                    <th style={{ width: 120, padding: '12px 6px' }}>Instruction</th>
                    <th style={{ width: 120, padding: '12px 6px' }}>Duration</th>
                    <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedConsultation.drugs?.map((drug, index) => (
                    <tr key={index}>
                      <td style={{ padding: '12px 6px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        <Box sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          <strong>{drug.tradeName}</strong> ({drug.genericName})
                        </Box>
                      </td>
                      <td style={{ padding: '12px 6px' }}>{drug.dosageValue}</td>
                      <td style={{ padding: '12px 6px' }}>{drug.dosageUnit}</td>
                      <td style={{ padding: '12px 6px' }}>{drug.frequency.join(', ')}</td>
                      <td style={{ padding: '12px 6px' }}>{drug.instruction}</td>
                      <td style={{ padding: '12px 6px' }}>{`${drug.duration} ${drug.durationUnit}`}</td>
                      <td style={{ padding: '12px 6px' }}>N/A</td>
                    </tr>
                  ))}
                  {selectedConsultation.drugs?.length === 0 && (
                    <tr>
                      <td colSpan={7} style={{ textAlign: 'center', padding: '12px 6px' }}>
                        No drugs prescribed
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Box>
          </Box>
        ) : (
          <Box sx={{ overflowY: 'auto', maxHeight: '75vh' }}>
            {last7Days.length > 0 && (
              <>
                <Typography level="h4" component="h3" mb={2}>
                  Last 7 Days
                </Typography>
                <Table aria-labelledby="last7days" stickyHeader>
                  <thead>
                    <tr>
                      <th>Appointment Date</th>
                      <th>Complaint Types</th>
                      <th>Template Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {last7Days.map((consultation) => (
                      <tr key={consultation.id}>
                        <td>{consultation.appointmentDate}</td>
                        <td>{consultation.complaints.map(c => c.type).join(', ')}</td>
                        <td>{consultation.template?.name || 'N/A'}</td>
                        <td>
                          <Button size="sm" onClick={() => handleViewDetails(consultation)}>
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}

            {last30Days.length > 0 && (
              <>
                <Typography level="h4" component="h3" mb={2}>
                  Last 30 Days
                </Typography>
                <Table aria-labelledby="last30days" stickyHeader>
                  <thead>
                    <tr>
                      <th>Appointment Date</th>
                      <th>Complaint Types</th>
                      <th>Template Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {last30Days.map((consultation) => (
                      <tr key={consultation.id}>
                        <td>{consultation.appointmentDate}</td>
                        <td>{consultation.complaints.map(c => c.type).join(', ')}</td>
                        <td>{consultation.template?.name || 'N/A'}</td>
                        <td>
                          <Button size="sm" onClick={() => handleViewDetails(consultation)}>
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}

            {olderAppointments.length > 0 && (
              <>
                <Typography level="h4" component="h3" mb={2}>
                  Older Appointments
                </Typography>
                <Table aria-labelledby="olderappointments" stickyHeader>
                  <thead>
                    <tr>
                      <th>Appointment Date</th>
                      <th>Complaint Types</th>
                      <th>Template Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {olderAppointments.map((consultation) => (
                      <tr key={consultation.id}>
                        <td>{consultation.appointmentDate}</td>
                        <td>{consultation.complaints.map(c => c.type).join(', ')}</td>
                        <td>{consultation.template?.name || 'N/A'}</td>
                        <td>
                          <Button size="sm" onClick={() => handleViewDetails(consultation)}>
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Box>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default PatientHistory;
