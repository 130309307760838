import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { IConsultation } from './Consultation';
import { IClinic } from './ClinicService';
import { IDoctor } from './ClinicService';

export const generatePDF = async (consultation: IConsultation, clinic: IClinic, doctor: IDoctor, reportType: string) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageMargin = 20;
  const footerHeight = 20;
  let yPosition = 20;

  const addFooter = () => {
    doc.setFontSize(10);
    const footerText = "Do not take any medicines without consulting your doctor.";
    const textWidth = doc.getTextWidth(footerText);
    const textX = (pageWidth - textWidth) / 2;
    doc.text(footerText, textX, pageHeight - 10);
  };

  const addHeader = () => {
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`${doctor.name} ${doctor.degree}`, pageMargin, yPosition);
    yPosition += 5;
    doc.setFont('times', 'normal');
    doc.text(`${doctor.designation}`, pageMargin, yPosition);

    doc.text(clinic.name, pageWidth - pageMargin - doc.getTextWidth(clinic.name), yPosition - 5);
    doc.text(clinic.address, pageWidth - pageMargin - doc.getTextWidth(clinic.address), yPosition);
    doc.text(clinic.phoneNumber, pageWidth - pageMargin - doc.getTextWidth(clinic.phoneNumber), yPosition + 5);

    yPosition += 10;
    doc.line(pageMargin, yPosition, pageWidth - pageMargin, yPosition);
    yPosition += 5; // Adjust space after the divider line
  };

  const addNewPage = () => {
    addFooter(); // Add footer before adding a new page
    doc.addPage();
    yPosition = 20; // Reset yPosition for new page
    //addHeader();
  };

  addHeader();

  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(consultation.patient.name, pageMargin, yPosition);
  doc.text('Consultation Date', pageWidth - pageMargin - doc.getTextWidth('Consultation Date'), yPosition);
  yPosition += 10;
  doc.setFont('times', 'normal');
  doc.text(`${consultation.patient.sex} (Age: ${consultation.patient.ageYear} years, ${consultation.patient.ageMonth} months)`, pageMargin, yPosition);
  doc.text(new Date(consultation.appointmentDate!).toLocaleString(), pageWidth - pageMargin - doc.getTextWidth(new Date(consultation.appointmentDate!).toLocaleString()), yPosition);
  yPosition += 5;
  doc.line(pageMargin, yPosition, pageWidth - pageMargin, yPosition);
  yPosition += 5; // Adjust space after the divider line

  // Conditional sections based on reportType
  if (reportType === 'FULL' || reportType === 'VITAL-TREAT') {
    // Add Vital Information as a table
    if (yPosition + 60 > pageHeight - footerHeight) addNewPage();
    doc.setFont('times', 'bold');
    doc.text('Vital Information:', pageMargin, yPosition);
    yPosition += 10;

    const vitalInformationData = [];
    vitalInformationData.push([`Weight: ${consultation.vitalInformation.weight}`, `Height: ${consultation.vitalInformation.height}`, `Temperature: ${consultation.vitalInformation.temperatureValue} ${consultation.vitalInformation.temperatureUnit}`]);
    vitalInformationData.push([`Head Circumference: ${consultation.vitalInformation.headCircumference}`, `Heart Rate: ${consultation.vitalInformation.heartRate}`, `Respiratory Rate: ${consultation.vitalInformation.respiratoryRate}`]);
    vitalInformationData.push([`BP (mm HG): ${consultation.vitalInformation.bp}`, `SPO2 (%): ${consultation.vitalInformation.oxygenSaturation}`, `CRT (seconds): ${consultation.vitalInformation.crt}`]);

    autoTable(doc, {
      startY: yPosition,
      body: vitalInformationData,
      margin: { top: 5, bottom: footerHeight, left: pageMargin, right: pageMargin },
      theme: 'grid',
      styles: { font: 'times', fontSize: 8 },
      didDrawPage: (data) => {
        if ( data.cursor ) 
        yPosition = data.cursor.y + 10; // Adjust space after the table
      },
    });

    if (yPosition + 5 > pageHeight - footerHeight) addNewPage();
    yPosition += 5;
    doc.line(pageMargin, yPosition, pageWidth - pageMargin, yPosition);
    yPosition += 5; // Adjust space after the divider line
  }

  if (reportType === 'FULL') {
    // Add Clinical Findings as a table
    if (yPosition + 60 > pageHeight - footerHeight) addNewPage();
    doc.setFont('times', 'bold');
    doc.text('Clinical Findings:', pageMargin, yPosition);
    yPosition += 10;

    const clinicalFindingsData = [];
    if (consultation.generalInformation) clinicalFindingsData.push([`General Examination: ${consultation.generalInformation}`, `RS: ${consultation.rs}`]);
    if (consultation.cvs) clinicalFindingsData.push([`CVS: ${consultation.cvs}`, `Abdomen: ${consultation.abdomen}`]);
    if (consultation.cns) clinicalFindingsData.push([`CNS: ${consultation.cns}`]);

    autoTable(doc, {
      startY: yPosition,
      body: clinicalFindingsData,
      margin: { top: 5, bottom: footerHeight, left: pageMargin, right: pageMargin },
      theme: 'grid',
      styles: { font: 'times', fontSize: 8 },
      didDrawPage: (data) => {
        if ( data.cursor ) 
        yPosition = data.cursor.y + 10; // Adjust space after the table
      },
    });

    if (yPosition + 5 > pageHeight - footerHeight) addNewPage();
    yPosition += 5;
    doc.line(pageMargin, yPosition, pageWidth - pageMargin, yPosition);
    yPosition += 5; // Adjust space after the divider line
  }

  // Add Diagnosis and Investigations (included in all report types)
  if (yPosition + 20 > pageHeight - footerHeight) addNewPage();
  doc.setFont('times', 'bold');
  doc.text(`Diagnosis:`, pageMargin, yPosition);
  doc.setFont('times', 'normal');
  if (consultation.template) {
    doc.text(`${consultation.template.name}`, pageMargin + 30, yPosition);
  } else {
    doc.text(`${consultation.diagnoseName}`, pageMargin + 30, yPosition);
  }

  yPosition += 10;

  if (consultation.investigations && consultation.investigations.length > 0) {
    doc.setFont('times', 'bold');
    doc.text('Investigations:', pageMargin, yPosition);
    doc.setFont('times', 'normal');
    doc.text(consultation.investigations.join(', '), pageMargin + 40, yPosition);
    yPosition += 10;
  }

  if (yPosition + 5 > pageHeight - footerHeight) addNewPage();
  doc.line(pageMargin, yPosition, pageWidth - pageMargin, yPosition);
  yPosition += 5; // Adjust space after the divider line

  // Add Treatment Section as a table (included in all report types)
  if (consultation.drugs && consultation.drugs.length > 0) {
    if (yPosition + 50 > pageHeight - footerHeight) addNewPage();
    doc.setFont('times', 'bold');
    doc.text('Treatment:', pageMargin, yPosition);
    yPosition += 10;
    const sortedDrugs = [...consultation.drugs].sort((a, b) => b.id - a.id);
    const tableData = sortedDrugs.map(drug => {
      let [morning, noon, evening, night] = ['', '', '', ''];
      if (drug.readableFrequency) {
        if (drug.readableFrequency.length === 4) {
          [morning, noon, evening, night] = drug.readableFrequency;
        } else {
          morning = drug.readableFrequency.join(', ');
        }
      }
      return [
        drug.type,
        drug.tradeName,
        morning,
        noon,
        evening,
        night,
        drug.instruction,
        `${drug.duration} ${drug.durationUnit}`,
        drug.notes
      ];
    });

    autoTable(doc, {
      startY: yPosition,
      head: [['Type', 'Name', 'Morning', 'Noon', 'Evening', 'Night', 'Instruction', 'Duration', 'Notes']],
      body: tableData,
      headStyles: {
        fillColor: [150, 150, 150], // Lighter black (gray) color
        textColor: [255, 255, 255], // White text
      },
      margin: { top: 5, bottom: footerHeight, left: pageMargin, right: pageMargin },
      theme: 'grid',
      styles: { font: 'times', fontSize: 10 },
      didDrawPage: (data) => {
        if ( data.cursor ) 
        yPosition = data.cursor.y + 10; // Adjust space after the table
      },
    });

    if (yPosition + 10 > pageHeight - footerHeight) addNewPage();
  }

  // Add Advice, Remarks, and Next Appointment Date (included in all report types)
  yPosition += 10;
  doc.setFont('times', 'bold');
  doc.text('Advice:', pageMargin, yPosition);
  doc.setFont('times', 'normal');
  doc.text(consultation.advices || 'N/A', pageMargin + 20, yPosition);
  yPosition += 10;

  if (yPosition > pageHeight - footerHeight) addNewPage();
  doc.setFont('times', 'bold');
  doc.text('Remarks:', pageMargin, yPosition);
  doc.setFont('times', 'normal');
  doc.text(consultation.remarks || 'N/A', pageMargin + 20, yPosition);
  yPosition += 10;

  if (yPosition > pageHeight - footerHeight) addNewPage();
  doc.setFont('times', 'bold');
  doc.text('Next Appointment Date:', pageMargin, yPosition);
  doc.setFont('times', 'normal');
  doc.text(consultation.nextVisitDate ? new Date(consultation.nextVisitDate).toLocaleDateString() : 'N/A', pageMargin + 50, yPosition);
  yPosition += 20;

  if (yPosition > pageHeight - footerHeight) addNewPage();
  // Add Doctor's Signature Placeholder
  doc.line(pageWidth - pageMargin - 80, yPosition, pageWidth - pageMargin, yPosition);
  doc.setFont('times', 'bold');
  yPosition += 10;
  doc.text(`${doctor.name} ${doctor.degree}`, pageWidth - pageMargin - 60, yPosition);
  yPosition += 10;
  doc.text(`${doctor.designation}`, pageWidth - pageMargin - 60, yPosition);
  yPosition += 10;

  // Ensure the footer is added to the last page as well
  addFooter();

  // Trigger the print dialog
  setTimeout(() => {
    const pdfUrl = URL.createObjectURL(doc.output('blob'));
    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.right = '0';
    iframe.style.bottom = '0';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.src = pdfUrl;
    iframe.onload = () => {
      iframe.contentWindow?.print();
    };
    document.body.appendChild(iframe);
  }, 1000); // Adjust the timeout duration if needed
};
