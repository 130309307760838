import React from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import Autocomplete from '@mui/joy/Autocomplete';
import { Typography } from '@mui/joy';
import { IConsultation } from '../../../service/Consultation';
import Box from '@mui/joy/Box';

const complaintsOptions = [
  "Cold",
  "Cough",
  "Fever",
  "Loose Stools",
  "Vomiting",
  "Abdominal Pain",
  "History",
  "Vaccination"
];

const tempUnits = ["C", "F"];

interface VitalInformationProps {
  consultation: IConsultation;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => void;
  handleSelectChange: (name: string, value: string[] | string | null) => void;
  errors: { [key: string]: string };
}

const VitalInformation: React.FC<VitalInformationProps> = ({ consultation, handleChange, handleSelectChange, errors }) => (
  <div>
    <Typography level="h4" component="h3" mb={2}>Vital Information</Typography>
    <Grid container spacing={2}>
      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Weight ( KG )</FormLabel>
            <Input
              size="sm"
              placeholder="Weight"
              name="vitalInformation.weight"
              value={consultation.vitalInformation.weight || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Height or Length ( CM )</FormLabel>
            <Input
              size="sm"
              placeholder="Height or Length"
              name="vitalInformation.height"
              value={consultation.vitalInformation.height || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>   



      <Grid xs={12} sm={6}>
          <FormLabel>Temperature</FormLabel>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FormControl sx={{ flex: 1 }}>
              <Input
                 size="sm"
                 placeholder="Temperature"
                 name="vitalInformation.temperatureValue"
                 value={consultation.vitalInformation.temperatureValue || ''}
                 onChange={handleChange}
              />    
            </FormControl>
            <FormControl sx={{ flex: 1, maxWidth: '100px' }}>
            <Select
              name="vitalInformation.temperatureUnit"
              value={consultation.vitalInformation.temperatureUnit || 'C'}
              onChange={(e, newValue) => handleSelectChange('vitalInformation.temperatureUnit', newValue)}
              size="sm"
            >
              {tempUnits.map((unit) => (
                <Option key={unit} value={unit}>
                  {unit}
                </Option>
              ))}
            </Select>
            </FormControl>
          </Box>
        </Grid>


      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Head Circumference ( CM )</FormLabel>
            <Input
              size="sm"
              placeholder="Head Circumference"
              name="vitalInformation.headCircumference"
              value={consultation.vitalInformation.headCircumference || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Heart Rate ( / min )</FormLabel>
            <Input
              size="sm"
              placeholder="Heart Rate"
              name="vitalInformation.heartRate"
              value={consultation.vitalInformation.heartRate || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Respiratory Rate ( / Min )</FormLabel>
            <Input
              size="sm"
              placeholder="Respiratory Rate"
              name="vitalInformation.respiratoryRate"
              value={consultation.vitalInformation.respiratoryRate || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>BP ( mm HG )</FormLabel>
            <Input
              size="sm"
              placeholder="BP"
              name="vitalInformation.bp"
              value={consultation.vitalInformation.bp || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>SPO2 ( % )</FormLabel>
            <Input
              size="sm"
              placeholder="SPO2"
              name="vitalInformation.oxygenSaturation"
              value={consultation.vitalInformation.oxygenSaturation || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>
      <Grid xs={12} sm={6}>
        <FormControl>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>CRT ( seconds )</FormLabel>
            <Input
              size="sm"
              placeholder="CRT"
              name="vitalInformation.crt"
              value={consultation.vitalInformation.crt || ''}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </Grid>
      
      
    </Grid>
  </div>
);

export default VitalInformation;
