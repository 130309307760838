import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Button from '@mui/joy/Button';
import { IConsultationDrug } from '../../../service/Consultation';

interface ConsultationDrugProps {
  drugs: IConsultationDrug[];
  onAddDrug: () => void;
  onEditDrug: (drug: IConsultationDrug) => void;
  onDeleteDrug: (tradeName: string) => void;
}

const ConsultationDrug: React.FC<ConsultationDrugProps> = ({ drugs, onAddDrug, onEditDrug, onDeleteDrug }) => {
  // Sort drugs based on their id
  const sortedDrugs = [...drugs].sort((a, b) => b.id - a.id);

  return (
    <div>
      <Typography level="h4" component="h3" mb={2}>Drugs</Typography>
      <Sheet
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          overflow: 'auto',
          mt: 2,
          maxHeight: 800,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
            overflow: 'auto',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 200, padding: '12px 6px' }}>Drug Info</th>              
              <th style={{ width: 100, padding: '12px 6px' }}>Dosage</th>
              <th style={{ width: 120, padding: '12px 6px' }}>Dosage Unit</th>
              <th style={{ width: 120, padding: '12px 6px' }}>Frequency</th>
              <th style={{ width: 120, padding: '12px 6px' }}>Instruction</th>
              <th style={{ width: 120, padding: '12px 6px' }}>Duration</th>
              <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedDrugs.map((drug, index) => (
              <tr key={index}>
                <td>
                  <Typography level="body-md">{drug.type}</Typography>
                  <Typography level="body-sm" sx={{ fontSize: '0.75rem' }}>{drug.tradeName}</Typography>
                </td>                
                <td>{drug.dosageValue}</td>
                <td>{drug.dosageUnit}</td>
                <td>{drug.frequency.join(', ')}</td>
                <td>{drug.instruction}</td>
                <td>{`${drug.duration} ${drug.durationUnit}`}</td>
                <td>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Link level="body-xs" component="button" startDecorator={<EditRoundedIcon />} onClick={() => onEditDrug(drug)}>
                      Edit
                    </Link>
                    <Link level="body-xs" component="button" startDecorator={<DeleteRoundedIcon />} color="danger" onClick={() => onDeleteDrug(drug.tradeName)}>
                      Delete
                    </Link>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Button
        size="sm"
        variant="solid"
        onClick={onAddDrug}
        sx={{ mt: 2 }}
      >
        Add Drug
      </Button>
    </div>
  );
};

export default ConsultationDrug;
