import React from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import { Typography } from '@mui/joy';

interface ConsultationClinicalFindingsProps {
  findings: {
    RS: string[];
    CSV: string[];
    Abdoman: string[];
    CNS: string[];
    GeneralExaminations: string[];
  };
  onFindingsChange: (name: string, value: string[]) => void;
}

const rsOptions = ["B/L Air Entry Equal","Tachypnea","Retraction","Crepitus Left","Crepitus Right","Wheeze","B/L Sound","No Added Sounds"];
const csvOptions = ["S1S2+","Tachycardia","No Murmur","Murmur+"];
const abdomanOptions = ["Soft","BS+","Tender","Distension","No Organomegaly","Palpable Liver","Palpable Spleen"];
const cnsOptions = ["No FND","No Meningeal Signs","Spinomotor System Normal","PERLA+"];
const generalExaminationsOptions = ["Alert / Active","Drowsy","Irritable","Lethargic","Playful","Sleeping","Comfortable at Rest"];

const ConsultationClinicalFindings: React.FC<ConsultationClinicalFindingsProps> = ({ findings, onFindingsChange }) => {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 'md',
        backgroundColor: 'background.level2',
      }}
    >
      <Typography level="h4" component="h3" mb={2}>
        Clinical Findings
      </Typography>

      <FormControl sx={{ mb: 2 }}>
        <FormLabel>General Examinations</FormLabel>
        <Autocomplete
          multiple
          options={generalExaminationsOptions}
          value={findings.GeneralExaminations}
          onChange={(event, newValue) => onFindingsChange('GeneralExaminations', newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ flex: 1 }}
        />
      </FormControl>

      <FormControl sx={{ mb: 2 }}>
        <FormLabel>RS</FormLabel>
        <Autocomplete
          multiple
          options={rsOptions}
          value={findings.RS}
          onChange={(event, newValue) => onFindingsChange('RS', newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ flex: 1 }}
        />
      </FormControl>

      <FormControl sx={{ mb: 2 }}>
        <FormLabel>Abdoman</FormLabel>
        <Autocomplete
          multiple
          options={abdomanOptions}
          value={findings.Abdoman}
          onChange={(event, newValue) => onFindingsChange('Abdoman', newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ flex: 1 }}
        />
      </FormControl>


      <FormControl sx={{ mb: 2 }}>
        <FormLabel>CSV</FormLabel>
        <Autocomplete
          multiple
          options={csvOptions}
          value={findings.CSV}
          onChange={(event, newValue) => onFindingsChange('CSV', newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ flex: 1 }}
        />
      </FormControl>

 
      <FormControl sx={{ mb: 2 }}>
        <FormLabel>CNS</FormLabel>
        <Autocomplete
          multiple
          options={cnsOptions}
          value={findings.CNS}
          onChange={(event, newValue) => onFindingsChange('CNS', newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ flex: 1 }}
        />
      </FormControl>

    
    </Box>
  );
};

export default ConsultationClinicalFindings;
