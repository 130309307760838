import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader
import { IPatient, searchPatients, getPatients } from '../../../service/PatientService';
import { getPatientHistory } from '../../../service/ConsultationService';
import PatientHistory from '../consultations/PatientHistory';
import { IConsultation } from '../../../service/Consultation';

interface PatientListProps {
  onEdit: (patientId: number) => void;
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const PatientList: React.FC<PatientListProps> = ({ onEdit, showSnackBar }) => {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [mobileNumberFilter, setMobileNumberFilter] = useState('');
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
  const [patientHistory, setPatientHistory] = useState<IConsultation[]>([]);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state for data fetching

  useEffect(() => {
    const fetchAllPatients = async () => {
      try {
        const allPatients = await getPatients();
        setPatients(allPatients);
      } catch (error) {
        console.error(error);
        showSnackBar(error + '', 'error');
      } finally {
        setLoading(false); // Stop loader after fetching data
      }
    };

    fetchAllPatients();
  }, [showSnackBar]);

  const handleSearch = async () => {
    setLoading(true); // Show loader while searching
    try {
      const filtered = await searchPatients(nameFilter, mobileNumberFilter);
      setPatients(filtered);
    } catch (error) {
      showSnackBar(error + '', 'error');
    } finally {
      setLoading(false); // Stop loader after search is complete
    }
  };

  const handleReset = async () => {
    setNameFilter('');
    setMobileNumberFilter('');
    setLoading(true); // Show loader while resetting
    try {
      const allPatients = await getPatients();
      setPatients(allPatients);
    } catch (error) {
      console.error(error);
      showSnackBar('Error resetting patient list', 'error');
    } finally {
      setLoading(false); // Stop loader after reset
    }
  };

  const handleViewHistory = async (patientId: number) => {
    setOpenHistoryModal(true); // Open the modal immediately
    setLoadingHistory(true); // Show loader while fetching history

    try {
      const history = await getPatientHistory(patientId);
      if (history && history.length > 0) {
        setPatientHistory(history);
      } else {
        setPatientHistory([]);
      }
    } catch (error) {
      showSnackBar('Failed to load patient history.', 'error');
      setOpenHistoryModal(false); // Close the modal on error
    } finally {
      setLoadingHistory(false); // Stop loader after fetching
    }
  };

  const tableHeight = Math.min(patients.length * 52 + 56, window.innerHeight - 200); // 52 is approximate row height, 56 is the header height

  return (
    <React.Fragment>
      <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
        <Input
          placeholder="Search by Name"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          sx={{ flex: 1 }}
        />
        <Input
          placeholder="Search by Mobile Number"
          value={mobileNumberFilter}
          onChange={(e) => setMobileNumberFilter(e.target.value)}
          sx={{ flex: 1 }}
        />
        <Button onClick={handleSearch} sx={{ flexShrink: 0 }}>
          Search
        </Button>
        <Button variant="outlined" onClick={handleReset} sx={{ flexShrink: 0, borderColor: 'primary.main', color: 'primary.main' }}>
          Reset
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : patients.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Typography level="h4">No Patient Found</Typography>
        </Box>
      ) : (
        <Sheet
          className="PatientTableContainer"
          variant="outlined"
          sx={{
            width: '100%',
            maxHeight: '80vh', // Maximum height of the table
            height: tableHeight,
            borderRadius: 'sm',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
              overflow: 'auto', // Enable scrolling within the table
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 100, padding: '12px 6px' }}>Display ID</th>
                <th style={{ width: 150, padding: '12px 6px' }}>Name</th>
                <th style={{ width: 150, padding: '12px 6px' }}>Mobile Number</th>
                <th style={{ width: 100, padding: '12px 6px' }}>Sex</th>
                <th style={{ width: 150, padding: '12px 6px' }}>City</th>
                <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient) => (
                <tr key={patient.id}>
                  <td>
                    <Typography level="body-xs">{patient.displayId}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{patient.name}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{patient.mobileNumber}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{patient.sex}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{patient.city}</Typography>
                  </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Link level="body-xs" component="button" onClick={() => handleViewHistory(patient.id)}>
                        View History
                      </Link>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      )}

      {/* Patient History Modal */}
      {openHistoryModal && (
        <PatientHistory
          open={openHistoryModal}
          onClose={() => setOpenHistoryModal(false)}
          history={patientHistory}
          loading={loadingHistory}
        />
      )}
    </React.Fragment>
  );
};

export default PatientList;
