import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/joy/Divider';
import {
  getAppointment,
  updateAppointment,
  createAppointment,
  searchPatientsByMobile,
  initialAppointment,
  calculateAge,
} from '../../../service/ConsultationService';
import { IConsultation, IPatient } from '../../../service/Consultation';
import PatientSearchResult from './PatientSearchResult';
import { CardActions } from '@mui/joy';
import dayjs, { Dayjs } from 'dayjs';
import PatientSearch from './PatientSearch';
import PatientInformation from './PatientInformation';
import VitalInformation from './VitalInformation';
import PaymentDetails from './PaymentDetails';
import { useAppContext } from '../../../contexts/AppContext';

interface AppointmentFormProps {
  onClose: () => void;
  appointmentId: number | null;
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({ onClose, appointmentId, showSnackBar }) => {
  const [appointment, setAppointment] = useState<IConsultation>({
    ...initialAppointment,
    vitalInformation: {
      ...initialAppointment.vitalInformation,
      temperatureUnit: 'F', 
    },
    paymentMode: 'Cash',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(true); // Set initial loading to true
  const [openPatientSearchModal, setOpenPatientSearchModal] = useState<boolean>(false);
  const [patientSelected, setPatientSelected] = useState<boolean>(false);
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [addingNewPatient, setAddingNewPatient] = useState<boolean>(false);
  const { clinic, doctor } = useAppContext();

  useEffect(() => {
    if (appointmentId) {
      getAppointment(appointmentId)
        .then((data) => {
          if (!data.vitalInformation.temperatureUnit) {
            data.vitalInformation.temperatureUnit = 'F'; 
          }
          if (!data.paymentMode) {
            data.paymentMode = 'Cash'; // Ensure payment mode is set to 'Cash' if undefined
          }
          // Calculate age based on date of birth
          if (data.patient.dateOfBirth) {
            const { age, ageYear, ageMonth } = calculateAge(data.patient.dateOfBirth);
            data.patient.age = age;
            data.patient.ageYear = ageYear;
            data.patient.ageMonth = ageMonth;
          }
          setAppointment(data);
          setPatientSelected(true);
        })
        .catch((error) => {
          console.error('Error fetching appointment:', error);
          showSnackBar('Failed to load appointment.', 'error');
        })
        .finally(() => {
          setLoading(false); // Set loading to false after data is fetched
        });
    } else {
      setLoading(false); // Set loading to false if creating a new appointment
    }
  }, [appointmentId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => {
    const name = e.target?.name || e.name;
    const val = e.target?.value ?? value;

    setAppointment((prevAppointment) => {
      const keys = name.split('.');
      if (keys.length === 2) {
        const [mainKey, subKey] = keys as [keyof IConsultation, string];
        return {
          ...prevAppointment,
          [mainKey]: {
            ...(prevAppointment[mainKey] as any),
            [subKey]: val,
          },
        };
      } else {
        return {
          ...prevAppointment,
          [name]: val,
        };
      }
    });

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSelectChange = (name: string, value: string[] | string | null) => {
    setAppointment((prevAppointment) => {
      const keys = name.split('.');
      if (keys.length === 2) {
        const [mainKey, subKey] = keys as [keyof IConsultation, string];
        return {
          ...prevAppointment,
          [mainKey]: {
            ...(prevAppointment[mainKey] as any),
            [subKey]: value,
          },
        };
      } else {
        return {
          ...prevAppointment,
          [name]: value,
        };
      }
    });

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSave = async () => {
    const newError: { [key: string]: string } = {};
  
    if (!appointment.patient.name) newError.patientName = 'Patient Name is required';
    if (!appointment.patient.mobileNumber) newError.mobileNumber = 'Mobile Number is required';
    if (!appointment.patient.sex) newError.sex = 'Sex is required';
    if (!appointment.patient.dateOfBirth) newError.dateOfBirth = 'Date of Birth is required';
    // if (!appointment.fees) newError.fees = 'Fees are required';
    // if (!appointment.paymentMode) newError.paymentMode = 'Payment Mode is required';
  
    setErrors(newError);
  
    if (Object.values(newError).some((error) => error)) {
      showSnackBar('Please fill in all required fields.' + JSON.stringify(newError), 'error');
      return;
    }
  
    const updatedComplaints = [...appointment.vitalInformation.complaints];
    if (appointment.vitalInformation.otherComplaints) {
      updatedComplaints.push(appointment.vitalInformation.otherComplaints);
    }
  
    setLoading(true);
    try {
      const appointmentData = {
        ...appointment,
        clinicId: clinic?.id,
        doctorId: doctor?.id,
        vitalInformation: {
          ...appointment.vitalInformation,
          complaints: updatedComplaints,
        },
      };
  
      if (appointmentId) {
        await updateAppointment(appointmentId, appointmentData);
        showSnackBar('Appointment Updated Successfully!!!', 'success');
      } else {
        await createAppointment(appointmentData);
        showSnackBar('Appointment Created Successfully!!!', 'success');
      }
    } catch (error: any) {
      showSnackBar(error.response?.data?.message || 'Error saving appointment.', 'error');
      console.log('Error saving data:', JSON.stringify(error));
    } finally {
      setLoading(false);
      onClose();
    }
  };
  

  const handlePatientSearch = async (mobileNumber: string) => {
    if (!mobileNumber) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        'patient.mobileNumber': 'Mobile Number is required',
      }));
      showSnackBar('Please enter a mobile number.', 'error');
      return;
    }

    try {
      const patients = await searchPatientsByMobile(mobileNumber);
      if (patients.length > 0) {
        setPatients(patients);
        setOpenPatientSearchModal(true);
      } else {
        handeAddNewPatient();
      }
    } catch (error) {
      console.error('Error searching patients:', error);
    }
  };

  const handeAddNewPatient = () => {
    setAddingNewPatient(true);
    setOpenPatientSearchModal(false);
  };

  const handlePatientSelect = (patient: IPatient) => {
    if (patient.dateOfBirth) {
      const { age, ageYear, ageMonth } = calculateAge(patient.dateOfBirth);
      patient.age = age;
      patient.ageYear = ageYear;
      patient.ageMonth = ageMonth;
    }

    setAppointment((prevAppointment) => ({
      ...prevAppointment,
      patient,
    }));
    setPatientSelected(true);
    setAddingNewPatient(false);
    setOpenPatientSearchModal(false);
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const { age, ageYear, ageMonth } = calculateAge(date.toISOString());

      setAppointment((prevAppointment) => ({
        ...prevAppointment,
        patient: {
          ...prevAppointment.patient,
          dateOfBirth: date.toISOString(),
          ageYear: ageYear,
          ageMonth: ageMonth,
          age: age,
        },
      }));
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
          <CircularProgress /> {/* Show a loading spinner */}
        </Box>
      ) : (
        <Card
          sx={{
            width: { xs: '100%', md: '75%' },
            p: 3,
            borderRadius: 'md',
            boxShadow: 'lg',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <Typography level="h4" component="h2" mb={2}>
            {appointmentId ? 'Edit Appointment' : 'Create Appointment'}
          </Typography>
          <Divider sx={{ my: 2 }} />

          {!appointmentId && (
            <>
              {/* Patient Search Section */}
              <PatientSearch
                appointment={appointment}
                handleChange={handleChange}
                handlePatientSearch={handlePatientSearch}
                errors={errors}
              />
              <Divider sx={{ my: 2 }} />
            </>
          )}

          {(patientSelected || addingNewPatient || appointmentId) && (
            <>
              {/* Patient Information Section */}
              <PatientInformation
                appointment={appointment}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                handleDateChange={handleDateChange}
                errors={errors}
              />

              <Divider sx={{ my: 2 }} />

              {/* Vital Information Section */}
              <VitalInformation
                appointment={appointment}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                errors={errors}
                editable={!appointmentId} 
              />

              <Divider sx={{ my: 2 }} />

              {/* Payment Details Section */}
              <PaymentDetails
                appointment={appointment}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
              />

              <Divider sx={{ my: 2 }} />
            </>
          )}

          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={handleSave}
              disabled={loading}
              startDecorator={loading && <CircularProgress size={16} />}
              sx={{ ml: 1 }}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </CardActions>
        </Card>
      )}
      {openPatientSearchModal && (
        <PatientSearchResult
          onClose={() => setOpenPatientSearchModal(false)}
          addNewPatient={handeAddNewPatient}
          onSelect={handlePatientSelect}
          patients={patients}
        />
      )}
    </Box>
  );
};

export default AppointmentForm;
