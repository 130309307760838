  import React, { useState, useEffect } from 'react';
  import Box from '@mui/joy/Box';
  import Button from '@mui/joy/Button';
  import FormControl from '@mui/joy/FormControl';
  import FormLabel from '@mui/joy/FormLabel';
  import Input from '@mui/joy/Input';
  import Typography from '@mui/joy/Typography';
  import Modal from '@mui/joy/Modal';
  import Card from '@mui/joy/Card';
  import CardActions from '@mui/joy/CardActions';
  import Select from '@mui/joy/Select';
  import Option from '@mui/joy/Option';
  import { ITemplateDrug } from '../../../service/TemplateService';
  import { getDrugsByType } from '../../../service/TemplateService';
  import { getDrug } from '../../../service/DrugService';

  interface TemplateDrugFormProps {
    onClose: () => void;
    onSave: (drug: ITemplateDrug) => void;
    drug?: ITemplateDrug | null;
  }

  const drugTypes = [
    "DROPS", "SYRUP", "TABLET", "SACHET", "CREAM", "OINTMENT", "LOTION", "EYE DROPS", "DPOWDER",
    "POWDER", "DUSTING POWDER", "GEL", "SPRAY", "CAPSULE", "INJECTION", "NASAL DROPS", "NASAL SPRAY",
    "MDI", "VACCINE", "RESPULES", "FLUIDS", "MISC", "EAR DROPS", "SOAP", "PROTEIN POWDER", "POWDER SUSPENSIONS"
  ];

  const instructions = ["NA","Before-Food", "After-Food", "Both-Eye", "Left-Eye", "Right-Eye", "Both-Ear", "Left-Ear", "Right-Ear", "Nasal"];
  const durationUnits = ["DAYS", "WEEKS", "MONTHS"];
  const frequencies = ["2nd-Hourly", "4th-Hourly", "6th-Hourly", "8th-Hourly", "12th-Hourly", "Morning", "Afternoon", "Evening", "Night", "SOS-/-If needed", "Once-a-Day", "Twice-a-Day", "Thrice-a-Day", "As-Advised"];
  

  const TemplateDrugForm: React.FC<TemplateDrugFormProps> = ({ onClose, onSave, drug: initialDrug }) => {
    const [drug, setDrug] = useState<ITemplateDrug>({
      id: 0,
      drugId: 0,
      type: '',
      tradeName: '',
      genericName: '',
      weight: '',
      dosageValue: '',
      dosageUnit: '',
      frequency: [],
      instruction: '',
      duration: 0,
      durationUnit: '',
      notes: ''
    });

    const [drugsByType, setDrugsByType] = useState<{ id: number, tradeName: string }[]>([]);
    const [isTradeNameDisabled, setIsTradeNameDisabled] = useState<boolean>(true);
    const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(true);

    useEffect(() => {
      if (initialDrug) {
        setDrug(initialDrug);
        setIsFieldsDisabled(false);
      }
    }, [initialDrug]);

    const handleDrugTypeChange = async (value: string | null) => {
      console.log(" value ==>? "+value)
      if (value) {
        const data = await getDrugsByType(value);
        setDrugsByType(data.payload);
        setIsTradeNameDisabled(false);
        setDrug(prevDrug => ({
          ...prevDrug,
          type: value,
        }));
      } else {
        setDrugsByType([]);
        setIsTradeNameDisabled(true);
      }
    };

    const handleTradeNameChange = async (value: string | null) => {
      if (value) {
        const selectedDrug = await getDrug(Number(value));
        setDrug(prevDrug => ({
          ...prevDrug,
          drugId: selectedDrug.id,
          tradeName: selectedDrug.tradeName,
          genericName: selectedDrug.genericName,
          weight: selectedDrug.weight,
          dosageUnit: selectedDrug.dosageUnit,
          frequency: selectedDrug.frequency,
          instruction: selectedDrug.instruction,
          duration: selectedDrug.duration,
          durationUnit: selectedDrug.durationUnit,
          notes: selectedDrug.notes || '',
        }));
        setIsFieldsDisabled(false);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any) => {
      if (e && e.target) {
        const { name, value } = e.target;
        setDrug(prevDrug => ({
          ...prevDrug,
          [name]: value,
        }));
      }
    };

    const handleAdd = () => {
      onSave(drug);
    };

    return (
      <Modal open onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Card sx={{ width: { xs: '100%', md: '50%' }, p: 3, borderRadius: 'md', boxShadow: 'lg', maxHeight: '90vh', overflow: 'auto' }}>
          <Typography level="h4" component="h2" mb={2}>
            {initialDrug ? 'Edit Drug' : 'Add Drug'}
          </Typography>
          {initialDrug ? (
            <Box sx={{ mb: 2 }}>
              <Typography level="body-md" component="h3">
                {drug.type}
              </Typography>
              <Typography level="body-sm" sx={{ fontSize: '0.875rem' }}>
                {drug.tradeName}
              </Typography>
            </Box>
          ) : (
            <>
              <FormControl>
                <FormLabel>Type</FormLabel>
                <Select
                  name="type"
                  value={drug.type}
                  onChange={(e, value) => handleDrugTypeChange(value)}
                  placeholder="Select Type"
                  required
                >
                  {drugTypes.map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Trade Name</FormLabel>
                <Select
                  name="tradeName"
                  value={drug.drugId.toString()}
                  onChange={(e, value) => handleTradeNameChange(value)}
                  placeholder="Select Trade Name"
                  disabled={isTradeNameDisabled}
                  required
                >
                  {drugsByType.map((drug) => (
                    <Option key={drug.id} value={drug.id.toString()}>
                      {drug.tradeName}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <FormControl>
            <FormLabel>Generic Name</FormLabel>
            <Input
              size="sm"
              placeholder="Generic Name"
              name="genericName"
              value={drug.genericName}
              onChange={handleChange}
              required
              disabled
            />
          </FormControl>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>MG / KG</FormLabel>
              <Input
                size="sm"
                placeholder="Weight"
                name="weight"
                value={drug.weight}
                onChange={handleChange}
                required
                disabled={isFieldsDisabled}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Dosage</FormLabel>
              <Input
                size="sm"
                placeholder="Dosage"
                name="dosageValue"
                value={drug.dosageValue}
                onChange={handleChange}
                required
                disabled={isFieldsDisabled}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Dosage Unit</FormLabel>
              <Input
                size="sm"
                placeholder="Dosage Unit"
                name="dosageUnit"
                value={drug.dosageUnit}
                onChange={handleChange}
                required
                disabled
              />
            </FormControl>
          </Box>
          <FormControl>
            <FormLabel>Frequency</FormLabel>
            <Select
              multiple
              name="frequency"
              value={drug.frequency}
              onChange={(e, value) => handleChange({ target: { name: 'frequency', value: value || [] } })}
              placeholder="Select Frequency"
              required
              disabled={isFieldsDisabled}
            >
              {frequencies.map((frequency) => (
                <Option key={frequency} value={frequency}>
                  {frequency}
                </Option>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Instruction</FormLabel>
              <Select
                name="instruction"
                value={drug.instruction}
                onChange={(e, value) => handleChange({ target: { name: 'instruction', value } })}
                placeholder="Select Instruction"
                required
                disabled={isFieldsDisabled}
              >
                {instructions.map((instruction) => (
                  <Option key={instruction} value={instruction}>
                    {instruction}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Duration</FormLabel>
              <Input
                size="sm"
                placeholder="Duration"
                name="duration"
                value={drug.duration}
                onChange={handleChange}
                required
                disabled={isFieldsDisabled}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Duration Unit</FormLabel>
              <Select
                name="durationUnit"
                value={drug.durationUnit}
                onChange={(e, value) => handleChange({ target: { name: 'durationUnit', value } })}
                placeholder="Select Duration Unit"
                required
                disabled={isFieldsDisabled}
              >
                {durationUnits.map((unit) => (
                  <Option key={unit} value={unit}>
                    {unit}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button size="sm" variant="outlined" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={handleAdd} sx={{ ml: 1 }}>
              {initialDrug ? 'Update' : 'Add'}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    );
  };

  export default TemplateDrugForm;
