import * as React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import DrugList from './DrugList';
import DrugForm from './DrugForm';
import Button from '@mui/joy/Button';
import SnackbarComponent from '../../ui/SnackBarComponent';

const Drug: React.FC = () => {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarColor, setSnackbarColor] = React.useState<'success' | 'error'>('success');

  const handleCreateDrug = () => {
    navigate('/drugs/new');
  };

  const handleEditDrug = (drugId: number) => {
    navigate(`/drugs/edit/${drugId}`);
  };

  const showSnackBar = (message: string, color: 'success' | 'error' = 'success') => {    
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography level="h1">Drugs</Typography>
                  <Button onClick={handleCreateDrug}>Create Drug</Button>
                </Box>
                <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 'sm', p: 2 }}>
                  <DrugList onEdit={handleEditDrug} showSnackBar={showSnackBar} />
                </Box>
              </Box>
            }
          />
          <Route
            path="new"
            element={<DrugForm onClose={() => navigate('/drugs')} drugId={null} showSnackBar={showSnackBar} />}
          />
          <Route
            path="edit/:drugId"
            element={<DrugFormWrapper onClose={() => navigate('/drugs')} showSnackBar={showSnackBar} />}
          />
        </Routes>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        color={snackbarColor}
      />
    </React.Fragment>
  );
};

const DrugFormWrapper: React.FC<{ onClose: () => void; showSnackBar: (message: string) => void }> = ({ onClose, showSnackBar }) => {
  const { drugId } = useParams<{ drugId: string }>();
  return <DrugForm onClose={onClose} drugId={drugId ? parseInt(drugId) : null} showSnackBar={showSnackBar} />;
};

export default Drug;
