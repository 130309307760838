import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Autocomplete from '@mui/joy/Autocomplete';
import CardActions from '@mui/joy/CardActions';
import Grid from '@mui/joy/Grid';
import Divider from '@mui/joy/Divider';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { ITemplate, ITemplateDrug, getTemplate, saveTemplate, updateTemplate } from '../../../service/TemplateService';
import TemplateDrugForm from './TemplateDrugForm';

interface TemplateFormProps {
  onClose: () => void;
  templateId: number | null;
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const investigationOptions = [
  "CBC, CRP",
  "Urine RE",
  "X-ray Chest AP",
  "X-ray Chest PA",
  "SGOT / SGPT",
  "Peripheral Smear",
  "USG ABD & KUB"
];

const statuses = ["Active", "Inactive"];

const TemplateForm: React.FC<TemplateFormProps> = ({ onClose, templateId, showSnackBar }) => {
  const [template, setTemplate] = useState<ITemplate>({
    id: 0,
    name: '',
    internalTemplateId: '',
    diagnosis: '',
    description: '',
    updatedBy: 0,
    doctor_id: 0,
    investigations: [],
    drugs: [],
    advices: '',
    remarks: '',
    active: true,
    createdAt: '',
    updatedAt: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(true); // Loader state
  const [openDrugModal, setOpenDrugModal] = useState<boolean>(false);
  const [editDrug, setEditDrug] = useState<ITemplateDrug | null>(null);

  useEffect(() => {
    if (templateId) {
      getTemplate(templateId)
        .then((data) => {
          setTemplate(data);
        })
        .catch((error) => {
          console.error('Error fetching template:', error);
          showSnackBar('Failed to load template.', 'error');
        })
        .finally(() => {
          setLoading(false); // Stop loading after data is fetched
        });
    } else {
      setLoading(false); // Stop loading if creating a new template
    }
  }, [templateId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => {
    const name = e.target?.name || e.name;
    const val = e.target?.value ?? value;

    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [name]: name === 'active' ? val === 'Active' : val,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSelectChange = (name: string, value: string | string[]) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSave = async () => {
    const newError = {
      name: !template.name ? 'Name is required' : '',
      diagnosis: !template.diagnosis ? 'Diagnosis is required' : '',
    //   advices: !template.advices ? 'Advices is required' : '',
    //   remarks: !template.remarks ? 'Remarks is required' : '',
    };

    setErrors(newError);

    if (Object.values(newError).some((error) => error)) {
      showSnackBar("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    try {
      if (templateId) {
        await updateTemplate(templateId, template);
        showSnackBar("Template Updated Successfully!!!");
      } else {
        await saveTemplate(template);
        showSnackBar("Template Created Successfully!!!");
      }
    } catch (error) {
      console.error('Error saving data:', error);
      showSnackBar('Failed to save template.', 'error');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleAddDrug = () => {
    setEditDrug(null);
    setOpenDrugModal(true);
  };

  const handleDrugModalClose = () => {
    setOpenDrugModal(false);
  };

  const handleDrugSave = (drug: ITemplateDrug) => {
    setTemplate((prevTemplate) => {
      const drugs = editDrug
        ? prevTemplate.drugs.map((d) => (d.tradeName === editDrug.tradeName ? drug : d))
        : [...prevTemplate.drugs, drug];
      return { ...prevTemplate, drugs };
    });
    setOpenDrugModal(false);
  };
  

  const handleEditDrug = (drug: ITemplateDrug) => {
    setEditDrug(drug);
    setOpenDrugModal(true);
  };

  const handleDeleteDrug = (tradeName: string) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      drugs: prevTemplate.drugs.filter((drug) => drug.tradeName !== tradeName),
    }));
  };
  

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
          <CircularProgress /> {/* Show a loading spinner */}
        </Box>
      ) : (
        <Card sx={{ width: { xs: '100%', md: '75%' }, p: 3, borderRadius: 'md', boxShadow: 'lg', maxHeight: '90vh', overflow: 'auto' }}>
          <Typography level="h4" component="h2" mb={2}>
            {templateId ? 'Template : '+template.internalTemplateId : 'Create Template'}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  size="sm"
                  placeholder="Name"
                  name="name"
                  value={template.name}
                  onChange={handleChange}
                  required
                  sx={{ borderColor: errors.name ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Diagnosis</FormLabel>
                <Input
                  size="sm"
                  placeholder="Diagnosis"
                  name="diagnosis"
                  value={template.diagnosis}
                  onChange={handleChange}
                  required
                  sx={{ borderColor: errors.diagnosis ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Investigations</FormLabel>
                <Autocomplete
                  multiple
                  options={investigationOptions}
                  value={template.investigations}
                  onChange={(e, newValue) => handleSelectChange('investigations', newValue)}                
                  isOptionEqualToValue={(option, value) => option === value}
                  sx={{ flex: 1 }}
                />
              </FormControl>
            </Grid>               
            <Grid xs={12}>
              <Sheet
                variant="outlined"
                sx={{
                  width: '100%',
                  borderRadius: 'sm',
                  overflow: 'auto',
                  mt: 2,
                  maxHeight: 300,
                }}
              >
                <Table
                  aria-labelledby="tableTitle"
                  stickyHeader
                  hoverRow
                  sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                    overflow: 'auto',
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: 200, padding: '12px 6px' }}>Drug Info</th>
                      <th style={{ width: 100, padding: '12px 6px' }}>MG</th>
                      <th style={{ width: 100, padding: '12px 6px' }}>Dosage</th>
                      <th style={{ width: 120, padding: '12px 6px' }}>Dosage Unit</th>
                      <th style={{ width: 120, padding: '12px 6px' }}>Frequency</th>
                      <th style={{ width: 120, padding: '12px 6px' }}>Instruction</th>
                      <th style={{ width: 120, padding: '12px 6px' }}>Duration</th>
                      <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
                    </tr>
                  </thead>  
                  <tbody>
                    {template.drugs.sort((a, b) => b.id - a.id).map((drug, index) => (
                      <tr key={index}>
                        <td>
                          <Typography level="body-md">{drug.type}</Typography>
                          <Typography level="body-sm" sx={{ fontSize: '0.75rem' }}>{drug.tradeName}</Typography>
                        </td>
                        <td>{drug.weight}</td>
                        <td>{drug.dosageValue}</td>
                        <td>{drug.dosageUnit}</td>
                        <td>{drug.frequency.join(', ')}</td>
                        <td>{drug.instruction}</td>
                        <td>{`${drug.duration} ${drug.durationUnit}`}</td>
                        <td>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Link level="body-xs" component="button" startDecorator={<EditRoundedIcon />} onClick={() => handleEditDrug(drug)}>                            
                            </Link>
                            <Link level="body-xs" component="button" startDecorator={<DeleteRoundedIcon />} color="danger" onClick={() => handleDeleteDrug(drug.tradeName)}>                            
                            </Link>
                          </Box>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Sheet>
            </Grid>
            <Grid xs={12}>
              <Button variant="outlined" color="primary" onClick={handleAddDrug}>
                Add Drug
              </Button>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Advices</FormLabel>
                <Textarea
                  size="sm"
                  placeholder="Advices"
                  minRows={3}
                  name="advices"
                  value={template.advices}
                  onChange={handleChange}
                  required
                  sx={{ borderColor: errors.advices ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Remarks</FormLabel>
                <Textarea
                  size="sm"
                  placeholder="Remarks"
                  minRows={3}
                  name="remarks"
                  value={template.remarks}
                  onChange={handleChange}
                  required
                  sx={{ borderColor: errors.remarks ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select
                  name="active"
                  value={template.active ? 'Active' : 'Inactive'}
                  onChange={(e, newValue) => handleChange({ name: 'active' }, newValue === 'Active')}
                  size="sm"
                  required
                  sx={{ borderColor: errors.active ? 'red' : '' }}
                >
                  {statuses.map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={handleSave}
              disabled={loading}
              startDecorator={loading && <CircularProgress size={16} />}
              sx={{ ml: 1 }}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </CardActions>
        </Card>
      )}
      {openDrugModal && (
        <TemplateDrugForm
          onClose={handleDrugModalClose}
          onSave={handleDrugSave}
          drug={editDrug}
        />
      )}
    </Box>
  );
};

export default TemplateForm;
