// src/components/modules/templates/Template.tsx

import * as React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import TemplateList from './TemplateList';
import TemplateForm from './TemplateForm';
import Button from '@mui/joy/Button';
import SnackbarComponent from '../../ui/SnackBarComponent';

const Template: React.FC = () => {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarColor, setSnackbarColor] = React.useState<'success' | 'error'>('success');

  const handleCreateTemplate = () => {
    navigate('/templates/new');
  };

  const handleEditTemplate = (templateId: number) => {
    navigate(`/templates/edit/${templateId}`);
  };

  const showSnackBar = (message: string, color: 'success' | 'error' = 'success') => {    
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography level="h1">Templates</Typography>
                  <Button onClick={handleCreateTemplate}>Create Template</Button>
                </Box>
                <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 'sm', p: 2 }}>
                  <TemplateList onEdit={handleEditTemplate} showSnackBar={showSnackBar} />
                </Box>
              </Box>
            }
          />
          <Route
            path="new"
            element={<TemplateForm onClose={() => navigate('/templates')} templateId={null} showSnackBar={showSnackBar} />}
          />
          <Route
            path="edit/:templateId"
            element={<TemplateFormWrapper onClose={() => navigate('/templates')} showSnackBar={showSnackBar} />}
          />
        </Routes>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        color={snackbarColor}
      />
    </React.Fragment>
  );
};

const TemplateFormWrapper: React.FC<{ onClose: () => void; showSnackBar: (message: string) => void }> = ({ onClose, showSnackBar }) => {
  const { templateId } = useParams<{ templateId: string }>();
  return <TemplateForm onClose={onClose} templateId={templateId ? parseInt(templateId) : null} showSnackBar={showSnackBar} />;
};

export default Template;
