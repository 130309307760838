import React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Grid from '@mui/joy/Grid';
import { IConsultation } from '../../../service/Consultation';

interface PatientSearchProps {
  appointment: IConsultation;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => void;
  handlePatientSearch: (mobileNumber: string) => void;
  errors: { [key: string]: string };
}

const PatientSearch: React.FC<PatientSearchProps> = ({ appointment, handleChange, handlePatientSearch, errors }) => {
return (
  <Grid container spacing={2}>
    <Grid xs={12}>
      <FormControl error={!!errors['patient.mobileNumber']}>
        <Box component="label" sx={{ display: 'block' }}>
          <FormLabel>Search Patient by Mobile Number</FormLabel>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Input
              size="sm"
              placeholder="Mobile Number"
              name="patient.mobileNumber"
              value={appointment.patient.mobileNumber}
              onChange={handleChange}
              sx={errors['patient.mobileNumber'] ? { borderColor: 'red' } : {}}
            />
            <Button variant="outlined" onClick={() => handlePatientSearch(appointment.patient.mobileNumber)}>
              Search
            </Button>
          </Box>
        </Box>
      </FormControl>
    </Grid>
  </Grid>
    );
};

export default PatientSearch;
