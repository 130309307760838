import { fetchData, saveData, updateData } from "../middleware/api";

export interface IDrug {
  id: number;
  type: string;
  tradeName: string;
  genericName: string;
  weight: string;
  frequency: string[];
  instruction: string;
  dosageUnit: string;
  duration: number;
  durationUnit: string;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  notes?:string;
}

export const getDrugs = async (): Promise<IDrug[]> => {
  const endpoint = '/api/drugs/';
  try {
    const data = await fetchData(endpoint);
    return data.payload;
  } catch (error) {
    throw error;
  }
};


export const getDrug = async (id:number): Promise<IDrug> => {
  const endpoint = '/api/drugs/'+id;
  try {
    const data = await fetchData(endpoint);
    return data.payload;
  } catch (error) {
    throw new Error('Error fetching drugs');
  }
};

// Save a new BProfile
export const saveDrug = async (drug: IDrug): Promise<IDrug> => {
  const payload = await saveData('/api/drugs/', drug);
  return payload;
};


export const updateDrug = async (drugId: number, drug: IDrug): Promise<IDrug> => {
  const payload = await updateData('/api/drugs/edit/'+drugId, drug);
  return payload;
};