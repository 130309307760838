import * as React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import PatientList from './PatientList';
import PatientForm from './PatientForm';
import Button from '@mui/joy/Button';
import SnackbarComponent from '../../ui/SnackBarComponent';

const Patient: React.FC = () => {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarColor, setSnackbarColor] = React.useState<'success' | 'error'>('success');



  const handleEditPatient = (patientId: number) => {
    navigate(`/patients/edit/${patientId}`);
  };

  
  const showSnackBar = (message: string, color: 'success' | 'error' = 'success') => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography level="h1">Patients</Typography>
                  
                </Box>
                <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 'sm', p: 2 }}>
                  <PatientList onEdit={handleEditPatient} showSnackBar={showSnackBar} />
                </Box>
              </Box>
            }
          />
          <Route
            path="new"
            element={<PatientForm onClose={() => navigate('/patients')} patientId={null} showSnackBar={showSnackBar} />}
          />
          <Route
            path="edit/:patientId"
            element={<PatientFormWrapper onClose={() => navigate('/patients')} showSnackBar={showSnackBar} />}
          />
        </Routes>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        color={snackbarColor}
      />
    </React.Fragment>
  );
};

const PatientFormWrapper: React.FC<{ onClose: () => void; showSnackBar: (message: string) => void }> = ({ onClose, showSnackBar }) => {
  const { patientId } = useParams<{ patientId: string }>();
  return <PatientForm onClose={onClose} patientId={patientId ? parseInt(patientId) : null} showSnackBar={showSnackBar} />;
};

export default Patient;
