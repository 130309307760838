import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Consultation from './components/modules/consultations/Consultation';
import Appointment from './components/modules/appointments/Appointment';
import Patient from './components/modules/patients/Patient';
import Drug from './components/modules/drugs/Drug';
import Template from './components/modules/templates/Template';
import { AppProvider } from './contexts/AppContext';

const App: React.FC = () => {
  return (
    <AppProvider>
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100vh',
            gap: 1,
          }}
        >
          <Routes>
            <Route path="/consultations/*" element={<Consultation />} />
            <Route path="/appointments/*" element={<Appointment />} />
            <Route path="/patients/*" element={<Patient />} />
            <Route path="/drugs/*" element={<Drug />} />
            <Route path="/templates/*" element={<Template />} />
            <Route path="/" element={<Consultation />} />
          </Routes>
        </Box>
      </Box>
    </CssVarsProvider>
    </AppProvider>
  );
};

export default App;
