import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/joy/Grid';
import Divider from '@mui/joy/Divider';
import { IPatient, getPatient, savePatient, updatePatient } from '../../../service/PatientService';

interface PatientFormProps {
  onClose: () => void;
  patientId: number | null;
  showSnackBar: (message: string) => void;
}

const PatientForm: React.FC<PatientFormProps> = ({ onClose, patientId, showSnackBar }) => {
  const [patient, setPatient] = useState<IPatient>({
    id: 0,
    displayId: '',
    name: '',
    mobileNumber: '',
    sex: '',
    age: '',
    ageYear: 0,
    ageMonth: 0,
    dateOfBirth: '',
    email: '',
    city: '',
    active: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (patientId) {
      setLoading(true);
      getPatient(patientId)
        .then((data) => {
          setPatient(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [patientId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setPatient((prevPatient) => ({
      ...prevPatient,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSave = async () => {
    const newError: { [key: string]: string } = {};

    if (!patient.name) newError.name = 'Name is required';
    if (!patient.mobileNumber) newError.mobileNumber = 'Mobile Number is required';

    setErrors(newError);

    if (Object.values(newError).some((error) => error)) {
      showSnackBar('Please fill in all required fields.');
      return;
    }

    setLoading(true);
    try {
      if (patientId) {
        await updatePatient(patientId, patient);
        showSnackBar('Patient updated successfully!');
      } else {
        await savePatient(patient);
        showSnackBar('Patient created successfully!');
      }
    } catch (error) {
      console.error('Error saving patient:', error);
      showSnackBar('Failed to save patient.');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'auto' }}>
      <Card sx={{ width: { xs: '100%', md: '75%' }, p: 3, borderRadius: 'md', boxShadow: 'lg', maxHeight: '90vh', overflow: 'auto' }}>
        <Typography level="h4" component="h2" mb={2}>
          {patientId ? 'Edit Patient' : 'Create Patient'}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                size="sm"
                placeholder="Name"
                name="name"
                value={patient.name}
                onChange={handleChange}
                required
                sx={{ borderColor: errors.name ? 'red' : '' }}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>Mobile Number</FormLabel>
              <Input
                size="sm"
                placeholder="Mobile Number"
                name="mobileNumber"
                value={patient.mobileNumber}
                onChange={handleChange}
                required
                sx={{ borderColor: errors.mobileNumber ? 'red' : '' }}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                size="sm"
                placeholder="Email"
                name="email"
                value={patient.email}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                size="sm"
                placeholder="City"
                name="city"
                value={patient.city}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                name="active"
                value={patient.active ? 'Active' : 'Inactive'}
                onChange={(e, newValue) => setPatient({ ...patient, active: newValue === 'Active' })}
                size="sm"
                required
              >
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="solid"
            onClick={handleSave}
            disabled={loading}
            startDecorator={loading && <CircularProgress size={16} />}
            sx={{ ml: 1 }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default PatientForm;
