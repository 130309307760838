import { fetchData, saveData, updateData } from "../middleware/api";

export interface IPatient {
  id: number;
  displayId: string;
  name: string;
  mobileNumber: string;
  sex: string;
  age: string;
  ageYear: number;
  ageMonth: number;
  dateOfBirth: string;
  email: string;
  city: string;
  active: boolean;
}

export const getPatients = async (): Promise<IPatient[]> => {
  const endpoint = '/api/patients/query';
  try {
    const data = await fetchData(endpoint);
    return data.payload;
  } catch (error) {
    throw new Error('Error fetching patients');
  }
};

export const searchPatients = async (name: string, mobileNumber: string): Promise<IPatient[]> => {
    // Check if either name or mobileNumber is provided
    if (!name && !mobileNumber) {
      throw new Error('Either name or mobile number is required to perform search.');
    }
  
    // Construct the query parameters dynamically based on valid inputs
    const queryParams: string[] = [];
  
    if (name) {
      queryParams.push(`name=${encodeURIComponent(name)}`);
    }
  
    if (mobileNumber) {
      queryParams.push(`mobileNumber=${encodeURIComponent(mobileNumber)}`);
    }
  
    // Combine the query parameters into a single query string
    const queryString = queryParams.join('&');
    const endpoint = `/api/patients/query?${queryString}`;
  
    try {
      const data = await fetchData(endpoint);
      return data.payload;
    } catch (error) {
      throw new Error('Error searching patients');
    }
  };
  

export const getPatient = async (id: number): Promise<IPatient> => {
  const endpoint = `/api/patients/${id}`;
  try {
    const data = await fetchData(endpoint);
    return data.payload;
  } catch (error) {
    throw new Error('Error fetching patient');
  }
};

export const savePatient = async (patient: IPatient): Promise<IPatient> => {
  const payload = await saveData('/api/patients/', patient);
  return payload;
};

export const updatePatient = async (patientId: number, patient: IPatient): Promise<IPatient> => {
  const payload = await updateData(`/api/patients/edit/${patientId}`, patient);
  return payload;
};
