import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import { getAppointments } from '../../../service/ConsultationService';
import { IConsultation } from '../../../service/Consultation';
import { useAppContext } from '../../../contexts/AppContext';

interface ConsultationListProps {
  onEdit: (consultationId: number) => void;
  showSnackBar: (message: string) => void;
}

const ConsultationList: React.FC<ConsultationListProps> = ({ onEdit, showSnackBar }) => {
  const [consultations, setConsultations] = React.useState<IConsultation[]>([]);
  const { clinic, doctor, loading } = useAppContext();
  const [activeTab, setActiveTab] = React.useState<number>(0); // 0 for Pending, 1 for Completed
  const [dataLoading, setDataLoading] = React.useState<boolean>(true); // Added state to track loading status

  const fetchConsultations = async () => {
    if (!loading && doctor) {
      try {
        const data = await getAppointments();
        setConsultations(data);
      } catch (error) {
        console.error(error);
        showSnackBar('Failed to load consultations.');
      } finally {
        setDataLoading(false); // Set loading to false after data is fetched
      }
    }
  };

  React.useEffect(() => {
    fetchConsultations();
  }, [loading, doctor]);

  const handleTabChange = (event: React.SyntheticEvent | null, newValue: string | number | null) => {
    if (typeof newValue === 'number') {
      setActiveTab(newValue);
    }
  };

  const filteredConsultations = consultations.filter((consultation) => {
    if (activeTab === 0) {
      return consultation.status === 'APPOINTMENT_CREATED';
    } else if (activeTab === 1) {
      return consultation.status === 'CONSULTATION_COMPLETED';
    }
    return false;
  });

  const tableHeight = Math.min(filteredConsultations.length * 60 + 56, window.innerHeight - 200); 

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        aria-label="Consultation tabs"
        value={activeTab}
        onChange={handleTabChange}
        sx={{ bgcolor: 'transparent' }}
      >
        <TabList
          disableUnderline
          sx={{
            p: 0.5,
            gap: 0.5,
            borderRadius: 'xl',
            bgcolor: 'background.surface', // Adjusted background color for compatibility with both light and dark modes
            [`& .MuiTab-root[aria-selected="true"]`]: {
              boxShadow: 'sm',
              bgcolor: 'primary.500', // Slightly different background for the selected tab
            },
            width: 'fit-content', // Width is now based on the content
            minWidth: 'fit-content', // Ensures the width does not shrink too much
            margin: 'auto', // Centers the TabList horizontally
          }}
        >
          <Tab
            disableIndicator
            sx={{
              [`&[aria-selected="true"]`]: {
                color: 'white', // Ensures the text color remains white when the tab is selected
              },
            }}
          >
            Pending
          </Tab>
          <Tab
            disableIndicator
            sx={{
              [`&[aria-selected="true"]`]: {
                color: 'white', // Ensures the text color remains white when the tab is selected
              },
            }}
          >
            Completed
          </Tab>
        </TabList>
      </Tabs>

      {dataLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh', // Adjust height as needed
          }}
        >
          <CircularProgress /> {/* Show a loading spinner */}
        </Box>
      ) : filteredConsultations.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh', // Adjust height as needed
            textAlign: 'center',
          }}
        >
          <Typography level="body-md" component="h2" mb={2}>
            {activeTab === 0 ? 'No Pending Consultations Today' : 'No Completed Consultations Found'}
          </Typography>
        </Box>
      ) : (
        <Sheet
          variant="outlined"
          sx={{
            width: '100%',
            maxHeight: '80vh', // Maximum height of the table
            height: tableHeight,
            borderRadius: 'sm',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
            mt: 2, // Added margin top to give space between tabs and table
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
              overflow: 'auto', // Enable scrolling within the table
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 100, padding: '12px 6px' }}>Token Number</th>
                <th style={{ width: 100, padding: '12px 6px' }}>Patient ID</th>
                <th style={{ width: 150, padding: '12px 6px' }}>Patient Name</th>
                <th style={{ width: 150, padding: '12px 6px' }}>Mobile Number</th>
                <th style={{ width: 200, padding: '12px 6px' }}>Symptoms</th>
                <th style={{ width: 100, padding: '12px 6px' }}>Remarks</th>              
                <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredConsultations.map((consultation) => (
                <tr key={consultation.id}>
                  <td>
                    <Typography level="body-xs">{consultation.tokenNumber}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{consultation.patient.displayId}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{consultation.patient.name}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{consultation.patient.mobileNumber}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">
                      {consultation.complaints?.map((complaint) => complaint.type).join(', ')}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{consultation.remarks}</Typography>
                  </td>                
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Button
                        variant="solid"
                        color="primary"
                        size="sm"
                        onClick={() => onEdit(consultation.id)}
                      >
                        {activeTab === 0 ? 'Start' : 'Edit'}
                      </Button>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      )}
    </Box>
  );
};

export default ConsultationList;
