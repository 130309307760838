import React from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import dayjs from 'dayjs';
import { Typography } from '@mui/joy';
import { IConsultation } from '../../../service/Consultation';

const genders = ["Male", "Female", "Other"];

interface PatientInformationProps {
  appointment: IConsultation;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => void;
  handleSelectChange: (name: string, value: string[] | string | null) => void;
  handleDateChange: (date: dayjs.Dayjs | null) => void;
  errors: { [key: string]: string };
}

const PatientInformation: React.FC<PatientInformationProps> = ({ appointment, handleChange, handleSelectChange, handleDateChange, errors }) => {
  const materialTheme = materialExtendTheme();
  const isConsultationCompleted = appointment.status === 'CONSULTATION_COMPLETED';

  return (
    <div>
      <Typography level="h4" component="h3" mb={2}>Patient Information</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              size="sm"
              placeholder="Name"
              name="patient.name"
              value={appointment.patient.name || ''}
              onChange={handleChange}
              disabled={isConsultationCompleted}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl error={!!errors['patient.mobileNumber']}>
            <FormLabel>Mobile Number</FormLabel>
            <Input
              size="sm"
              placeholder="Mobile Number"
              name="patient.mobileNumber"
              value={appointment.patient.mobileNumber || ''}
              onChange={handleChange}
              sx={errors['patient.mobileNumber'] ? { borderColor: 'red' } : {}}
              disabled={isConsultationCompleted}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              size="sm"
              placeholder="Email"
              name="patient.email"
              value={appointment.patient.email || ''}
              onChange={handleChange}
              disabled={isConsultationCompleted}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Select
              name="patient.sex"
              value={appointment.patient.sex || ''}
              onChange={(e, newValue) => handleSelectChange('patient.sex', newValue)}
              size="sm"
              disabled={isConsultationCompleted}
            >
              {genders.map((gender) => (
                <Option key={gender} value={gender}>
                  {gender}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>City</FormLabel>
            <Input
              size="sm"
              placeholder="City"
              name="patient.city"
              value={appointment.patient.city || ''}
              onChange={handleChange}
              disabled={isConsultationCompleted}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel htmlFor="patient-dob">Date of Birth</FormLabel>
            <MaterialCssVarsProvider
              theme={{ [MATERIAL_THEME_ID]: materialTheme }}
              defaultMode="system"
            >
              <JoyCssVarsProvider defaultMode="system">
                <CssBaseline enableColorScheme />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={appointment.patient.dateOfBirth ? dayjs(appointment.patient.dateOfBirth) : null}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small', id: 'patient-dob' } }}
                    disabled={isConsultationCompleted}
                  />
                </LocalizationProvider>
              </JoyCssVarsProvider>
            </MaterialCssVarsProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Age</FormLabel>
            <Input
              size="sm"
              placeholder="Age"
              name="patient.age"
              value={appointment.patient.age || ''}
              onChange={handleChange}
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientInformation;
