import React from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import { Typography } from '@mui/joy';
import { IConsultation } from '../../../service/Consultation';

const paymentModes = ["Cash", "UPI", "Card"];

interface PaymentDetailsProps {
  appointment: IConsultation;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => void;
  handleSelectChange: (name: string, value: string[] | string | null) => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ appointment, handleChange, handleSelectChange }) => {
  return (
    <div>
      <Typography level="h4" component="h3" mb={2}>Payment Details</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <FormControl>
            <Box component="label" sx={{ display: 'block' }}>
              <FormLabel>Fees</FormLabel>
              <Input
                size="sm"
                placeholder="Fees"
                name="fees"
                value={appointment.fees}
                onChange={handleChange}
              />
            </Box>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <Box component="label" sx={{ display: 'block' }}>
              <FormLabel>Payment Mode</FormLabel>
              <Select
                name="paymentMode"
                value={appointment.paymentMode}
                onChange={(e, newValue) => handleSelectChange('paymentMode', newValue)}
                size="sm"
              >
                {paymentModes.map((mode) => (
                  <Option key={mode} value={mode}>
                    {mode}
                  </Option>
                ))}
              </Select>
            </Box>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentDetails;
