import { fetchData } from "../middleware/api";

export interface IClinic {
  id: number;
  name: string;
  speciality: string;
  address: string;
  weight: string;
  pincode: string;
  phoneNumber: string;
}


export interface IUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role:String;
  }
  

export interface IDoctor {
    id: number;
    name: string;
    degree: string;
    designation: string;
    mobileNumber: string;    
}

export const getClinics = async (): Promise<IClinic[]> => {
    const endpoint = '/api/clinics/';
    try {
      const data = await fetchData(endpoint);
      return data.payload;
    } catch (error) {
      throw new Error('Error fetching clinics');
    }
  };

  export const getCurrentUser = async (): Promise<IUser> => {
    const endpoint = '/api/users/current';
    try {
      const data = await fetchData(endpoint);
      return data.payload;
    } catch (error) {
      throw new Error('Error fetching clinics');
    }
  };
  
  export const getDoctors = async (clinicId: number): Promise<IDoctor[]> => {
    const endpoint = `/api/doctors/?clinicId=${clinicId}`;
    try {
      const data = await fetchData(endpoint);
      return data.payload;
    } catch (error) {
      throw new Error('Error fetching doctors');
    }
  };
