import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { Typography, Button } from '@mui/joy';
import { IConsultation } from '../../../service/Consultation';
import dayjs from 'dayjs';
import PatientHistory from './PatientHistory';
import { getPatientHistory } from '../../../service/ConsultationService';
import FormLabel from '@mui/joy/FormLabel';
import Grid from '@mui/joy/Grid';

interface PatientInformationProps {
  consultation: IConsultation;
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const PatientInformation: React.FC<PatientInformationProps> = ({ consultation, showSnackBar }) => {
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
  const [patientHistory, setPatientHistory] = useState<IConsultation[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Initially true to show loader when modal opens

  const handleViewHistory = async () => {
    setOpenHistoryModal(true); // Open the modal immediately
    try {
      const history = await getPatientHistory(consultation.patient.id);
      if (history && history.length > 0) {
        setPatientHistory(history);
      } else {
        setPatientHistory([]);
      }
    } catch (error) {
      setOpenHistoryModal(false);
      showSnackBar('Failed to load patient history.', 'error');
    } finally {
      setLoading(false); // Stop showing the loader once the data is fetched
    }
  };

  const handleClose = () => {
    setPatientHistory([]);
    setOpenHistoryModal(false);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography level="h4" component="h3">
          Patient Information
        </Typography>
        <Button size="sm" variant="outlined" color="primary" onClick={handleViewHistory}>
          View History
        </Button>
      </Box>

      {/* Patient Details Grid */}
      <Grid container spacing={2}>
        <Grid xs={12} sm={3}>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Name</FormLabel>
            <Typography>{consultation.patient.name || 'N/A'}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} sm={3}>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Mobile Number</FormLabel>
            <Typography>{consultation.patient.mobileNumber || 'N/A'}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} sm={3}>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Email</FormLabel>
            <Typography>{consultation.patient.email || 'N/A'}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} sm={3}>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Gender</FormLabel>
            <Typography>{consultation.patient.sex || 'N/A'}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid xs={12} sm={3}>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>City</FormLabel>
            <Typography>{consultation.patient.city || 'N/A'}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} sm={3}>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Date of Birth</FormLabel>
            <Typography>
              {consultation.patient.dateOfBirth ? dayjs(consultation.patient.dateOfBirth).format('DD/MM/YYYY') : 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} sm={3}>
          <Box component="label" sx={{ display: 'block' }}>
            <FormLabel>Age</FormLabel>
            <Typography>{consultation.patient.age || 'N/A'}</Typography>
          </Box>
        </Grid>
        {/* Fourth Column Placeholder if needed */}
        <Grid xs={12} sm={3}></Grid>
      </Grid>

      {/* Patient History Modal */}
      {openHistoryModal && (
        <PatientHistory
          open={openHistoryModal}
          onClose={handleClose}
          history={patientHistory}
          loading={loading} // Pass the loading state to the modal
        />
      )}
    </div>
  );
};

export default PatientInformation;
