// src/contexts/AppContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { getClinics, getDoctors, IClinic, IDoctor, IUser, getCurrentUser } from '../service/ClinicService';

interface AppContextProps {
  clinic?: IClinic;
  doctor?: IDoctor;
  user? : IUser;
  loading: boolean;
  fetchClinicAndDoctor: () => void;
}

interface AppProviderProps {
  children: ReactNode;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [clinic, setClinic] = useState<IClinic>();
  const [doctor, setDoctor] = useState<IDoctor>();
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchClinicAndDoctor = async () => {
    setLoading(true);
    try {
      const clinicData = await getClinics();
      if (clinicData.length > 0) {
        setClinic(clinicData[0]);
        const doctorData = await getDoctors(clinicData[0].id);
        if (doctorData.length > 0) {          
          setDoctor(doctorData[0]);
        }
        const userData = await getCurrentUser()
        setUser(userData)
        console.log("user data ==="+JSON.stringify(userData))
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClinicAndDoctor();
  }, []);

  return (
    <AppContext.Provider value={{ clinic, doctor, user,loading, fetchClinicAndDoctor }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
