import React, { useEffect } from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import Autocomplete from '@mui/joy/Autocomplete';
import { Textarea, Typography } from '@mui/joy';
import { IConsultation } from '../../../service/Consultation';
import Box from '@mui/joy/Box';

const complaintsOptions = [
  "Cold",
  "Cough",
  "Fever",
  "Loose Stools",
  "Vomiting",
  "Abdominal Pain",
  "History",
  "Vaccination"
];

const tempUnits = ["C", "F"];

interface VitalInformationProps {
  appointment: IConsultation;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => void;
  handleSelectChange: (name: string, value: string[] | string | null) => void;
  errors: { [key: string]: string };
  editable: boolean; 
}

const VitalInformation: React.FC<VitalInformationProps> = ({ appointment, handleChange, handleSelectChange, errors, editable }) => {

  useEffect(() => {
    // Filter non-standard complaints
    const nonStandardComplaints = appointment.vitalInformation.complaints.filter(
      complaint => !complaintsOptions.includes(complaint)
    );

    // Set non-standard complaints to otherComplaints
    if (nonStandardComplaints.length > 0) {
      handleSelectChange('vitalInformation.otherComplaints', nonStandardComplaints.join(', '));

      // Remove non-standard complaints from the complaints array
      handleSelectChange(
        'vitalInformation.complaints',
        appointment.vitalInformation.complaints.filter(
          complaint => complaintsOptions.includes(complaint)
        )
      ); 
    }
  }, [appointment.vitalInformation.complaints]);

  return (
    <div>
      <Typography level="h4" component="h3" mb={2}>Vital Information</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Weight ( KG )</FormLabel>
            <Input
              size="sm"
              placeholder="Weight"
              name="vitalInformation.weight"
              value={appointment.vitalInformation.weight || ''}
              onChange={handleChange}              
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Height or Length ( CM )</FormLabel>
            <Input
              size="sm"
              placeholder="Height or Length"
              name="vitalInformation.height"
              value={appointment.vitalInformation.height || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Head Circumference ( CM )</FormLabel>
            <Input
              size="sm"
              placeholder="Head Circumference"
              name="vitalInformation.headCircumference"
              value={appointment.vitalInformation.headCircumference || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormLabel>Temperature</FormLabel>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FormControl sx={{ flex: 1 }}>
              <Input
                size="sm"
                placeholder="Temperature"
                name="vitalInformation.temperatureValue"
                value={appointment.vitalInformation.temperatureValue || ''}
                onChange={handleChange}
                
              />    
            </FormControl>
            <FormControl sx={{ flex: 1, maxWidth: '100px' }}>
              <Select
                name="vitalInformation.temperatureUnit"
                value={appointment.vitalInformation.temperatureUnit || 'F'}
                onChange={(e, newValue) => handleSelectChange('vitalInformation.temperatureUnit', newValue)}
                size="sm"
                
              >
                {tempUnits.map((unit) => (
                  <Option key={unit} value={unit}>
                    {unit}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Heart Rate ( / min )</FormLabel>
            <Input
              size="sm"
              placeholder="Heart Rate"
              name="vitalInformation.heartRate"
              value={appointment.vitalInformation.heartRate || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Respiratory Rate ( / Min )</FormLabel>
            <Input
              size="sm"
              placeholder="Respiratory Rate"
              name="vitalInformation.respiratoryRate"
              value={appointment.vitalInformation.respiratoryRate || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>BP ( mm HG )</FormLabel>
            <Input
              size="sm"
              placeholder="BP"
              name="vitalInformation.bp"
              value={appointment.vitalInformation.bp || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>SPO2 ( % )</FormLabel>
            <Input
              size="sm"
              placeholder="SPO2"
              name="vitalInformation.oxygenSaturation"
              value={appointment.vitalInformation.oxygenSaturation || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>CRT ( seconds )</FormLabel>
            <Input
              size="sm"
              placeholder="CRT"
              name="vitalInformation.crt"
              value={appointment.vitalInformation.crt || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Complaints</FormLabel>
            <Autocomplete
              multiple
              options={complaintsOptions}
              value={appointment.vitalInformation.complaints || []}
              onChange={(e, newValue) => handleSelectChange('vitalInformation.complaints', newValue)}
              isOptionEqualToValue={(option, value) => option === value || value === appointment.vitalInformation.otherComplaints}
              getOptionLabel={(option) => option}
              freeSolo
              sx={{ flex: 1 }}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Other Complaints</FormLabel>
            <Input
              size="sm"
              placeholder="Other Complaints"
              name="vitalInformation.otherComplaints"
              value={appointment.vitalInformation.otherComplaints || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Duration of Illness ( Days )</FormLabel>
            <Input
              size="sm"
              placeholder="Duration of Illness"
              name="vitalInformation.complaintDuration"
              value={appointment.vitalInformation.complaintDuration || ''}
              onChange={handleChange}
              
              
            />
          </FormControl>
        </Grid>
        
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Remarks</FormLabel>   
            <Textarea
              size="sm"
              placeholder="Remarks"
              name="remarks"              
              minRows={3}
              value={appointment.remarks || ''}
              onChange={handleChange}
              
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default VitalInformation;
