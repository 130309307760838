import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Card from '@mui/joy/Card';
import { getTemplates, ITemplate } from '../../../service/TemplateService';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';

interface TemplateListProps {
  onEdit: (templateId: number) => void;  
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const TemplateList: React.FC<TemplateListProps> = ({ onEdit, showSnackBar }) => {
  const [templates, setTemplates] = React.useState<ITemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = React.useState<ITemplate[]>([]);
  const [idFilter, setIdFilter] = React.useState('');
  const [diagnosisFilter, setDiagnosisFilter] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(true); // Loader state

  const fetchTemplates = async () => {
    try {
      const data = await getTemplates();        
      setTemplates(data);
      setFilteredTemplates(data);
    } catch (error) {
      console.error(error);
      showSnackBar('Failed to fetch templates', 'error');
    } finally {
      setLoading(false); // Stop loading once data is fetched
    }
  };

  React.useEffect(() => {
    fetchTemplates();
  }, []);

  const handleSearch = () => {
    const filtered = templates.filter(
      (template) =>
        template.internalTemplateId.toLowerCase().includes(idFilter.toLowerCase()) &&
        template.diagnosis.toLowerCase().includes(diagnosisFilter.toLowerCase()) &&
        (statusFilter === '' || (statusFilter === 'active' && template.active) || (statusFilter === 'inactive' && !template.active))
    );
    setFilteredTemplates(filtered);
  };

  const handleReset = () => {
    setIdFilter('');
    setDiagnosisFilter('');
    setStatusFilter('');
    setFilteredTemplates(templates);
  };

  const tableHeight = Math.min(filteredTemplates.length * 52 + 56, window.innerHeight - 200); // 52 is approximate row height, 56 is the header height

  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ mb: 2, p: 2, borderColor: 'divider' }}>
        <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Input
            placeholder="Search by ID"
            value={idFilter}
            onChange={(e) => setIdFilter(e.target.value)}
            sx={{ flex: 1 }}
          />
          <Input
            placeholder="Search by Diagnosis"
            value={diagnosisFilter}
            onChange={(e) => setDiagnosisFilter(e.target.value)}
            sx={{ flex: 1 }}
          />
          <Select
            placeholder="Status"
            value={statusFilter}
            onChange={(e, newValue) => setStatusFilter(newValue ?? '')}
            sx={{ flex: 1 }}
          >
            <Option value="">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
          <Button onClick={handleSearch} sx={{ flexShrink: 0 }}>
            Search
          </Button>
          <Button variant="outlined" onClick={handleReset} sx={{ flexShrink: 0, borderColor: 'primary.main', color: 'primary.main' }}>
            Reset
          </Button>
        </Box>
      </Card>
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : filteredTemplates.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Typography level="h4">No Templates Found</Typography>
        </Box>
      ) : (
        <Sheet
          className="TemplateTableContainer"
          variant="outlined"
          sx={{
            width: '100%',
            maxHeight: '80vh', // Maximum height of the table
            height: tableHeight,
            borderRadius: 'sm',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
              overflow: 'auto', // Enable scrolling within the table
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 120, padding: '12px 6px' }}>ID</th>
                <th style={{ width: 160, padding: '12px 6px' }}>Diagnosis</th>
                <th style={{ width: 120, padding: '12px 6px' }}>Status</th>
                <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredTemplates.map((template) => (
                <tr key={template.id}>
                  <td>
                    <Typography level="body-xs">{template.internalTemplateId}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{template.diagnosis}</Typography>
                  </td>
                  <td>
                    <Chip
                      variant="soft"
                      size="sm"
                      startDecorator={
                        template.active ? <CheckRoundedIcon /> : <BlockIcon />
                      }
                      color={template.active ? 'success' : 'danger'}  
                    >
                      {template.active ? 'Active' : 'Inactive'}
                    </Chip>
                  </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Link level="body-xs" component="button" startDecorator={<EditRoundedIcon />} onClick={() => onEdit(template.id)}>
                        Edit
                      </Link>                    
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      )}
    </React.Fragment>
  );
};

export default TemplateList;
