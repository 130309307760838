import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Autocomplete from '@mui/joy/Autocomplete';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';
import Chip from '@mui/joy/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'; // Import CheckRoundedIcon
import BlockIcon from '@mui/icons-material/Block'; // Import BlockIcon
import { getDrugs, IDrug } from '../../../service/DrugService';

const drugTypes = [
  "DROPS", "SYRUP", "TABLET", "SACHET", "CREAM", "OINTMENT", "LOTION", "EYE DROPS", "DPOWDER", 
  "POWDER", "DUSTING POWDER", "GEL", "SPRAY", "CAPSULE", "INJECTION", "NASAL DROPS", "NASAL SPRAY", 
  "MDI", "VACCINE", "RESPULES", "FLUIDS", "MISC", "EAR DROPS", "SOAP", "PROTEIN POWDER", "POWDER SUSPENSIONS"
];

interface DrugListProps {
  onEdit: (drugId: number) => void;
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const DrugList: React.FC<DrugListProps> = ({ onEdit, showSnackBar }) => {
  const [drugs, setDrugs] = useState<IDrug[]>([]);
  const [filteredDrugs, setFilteredDrugs] = useState<IDrug[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [genericNameFilter, setGenericNameFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [loading, setLoading] = useState<boolean>(true); // Add loading state

  const fetchDrugs = async () => {
    try {
      const data = await getDrugs();
      setDrugs(data);
      setFilteredDrugs(data);
    } catch (error) {
      console.error(error);
      showSnackBar('Failed to fetch drugs.'+error, 'error');
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  useEffect(() => {
    fetchDrugs();
  }, []);

  const handleSearch = () => {
    const filtered = drugs.filter(
      (drug) =>
        drug.tradeName.toLowerCase().includes(nameFilter.toLowerCase()) &&
        drug.genericName.toLowerCase().includes(genericNameFilter.toLowerCase()) &&
        (typeFilter === null || drug.type.toLowerCase() === typeFilter.toLowerCase()) &&
        (statusFilter === '' || (statusFilter === 'active' && drug.active) || (statusFilter === 'inactive' && !drug.active))
    );
    setFilteredDrugs(filtered);
  };

  const handleReset = () => {
    setNameFilter('');
    setGenericNameFilter('');
    setTypeFilter(null);
    setStatusFilter('');
    setFilteredDrugs(drugs);
  };

  const tableHeight = Math.min(filteredDrugs.length * 52 + 56, window.innerHeight - 200); // 52 is approximate row height, 56 is the header height

  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ mb: 2, p: 2, borderColor: 'divider' }}>
        <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Autocomplete
            placeholder="Search by Type"
            options={drugTypes}
            value={typeFilter}
            onChange={(event, newValue) => setTypeFilter(newValue ?? '')}
            sx={{ flex: 1 }}
          />
          <Input
            placeholder="Search by Trade Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            sx={{ flex: 1 }}
          />
          <Input
            placeholder="Search by Generic Name"
            value={genericNameFilter}
            onChange={(e) => setGenericNameFilter(e.target.value)}
            sx={{ flex: 1 }}
          />
          <Select
            placeholder="Status"
            value={statusFilter}
            onChange={(e, newValue) => setStatusFilter(newValue ?? '')}
            sx={{ flex: 1 }}
          >
            <Option value="">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
          <Button onClick={handleSearch} sx={{ flexShrink: 0 }}>
            Search
          </Button>
          <Button variant="outlined" onClick={handleReset} sx={{ flexShrink: 0, borderColor: 'primary.main', color: 'primary.main' }}>
            Reset
          </Button>
        </Box>
      </Card>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : filteredDrugs.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Typography level="h4">No Drugs Found</Typography>
        </Box>
      ) : (
        <Sheet
          className="DrugTableContainer"
          variant="outlined"
          sx={{
            width: '100%',
            maxHeight: '80vh', // Maximum height of the table
            height: tableHeight,
            borderRadius: 'sm',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
              overflow: 'auto', // Enable scrolling within the table
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 120, padding: '12px 6px' }}>Type</th>
                <th style={{ width: 160, padding: '12px 6px' }}>Trade Name</th>
                <th style={{ width: 160, padding: '12px 6px' }}>Generic Name</th>
                <th style={{ width: 120, padding: '12px 6px' }}>Status</th>
                <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredDrugs.map((drug) => (
                <tr key={drug.id}>
                  <td>
                    <Typography level="body-xs">{drug.type}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{drug.tradeName}</Typography>
                  </td>
                  <td>
                    <Typography level="body-xs">{drug.genericName}</Typography>
                  </td>
                  <td>
                    <Chip
                      variant="soft"
                      size="sm"
                      startDecorator={
                        drug.active ? <CheckRoundedIcon /> : <BlockIcon />
                      }
                      color={drug.active ? 'success' : 'danger'}  
                    >
                      {drug.active ? 'Active' : 'Inactive'}
                    </Chip>
                  </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Link level="body-xs" component="button" startDecorator={<EditRoundedIcon />} onClick={() => onEdit(drug.id)}>
                        Edit
                      </Link>                    
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      )}
    </React.Fragment>
  );
};

export default DrugList;
