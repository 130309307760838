import * as React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import AppointmentList from './AppointmentList';
import AppointmentForm from './AppointmentForm';
import Button from '@mui/joy/Button';
import SnackbarComponent from '../../ui/SnackBarComponent';

const Appointment: React.FC = () => {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarColor, setSnackbarColor] = React.useState<'success' | 'error'>('success');

  const handleCreateAppointment = () => {
    navigate('/appointments/new');
  };

  const handleEditAppointment = (appointmentId: number) => {
    navigate(`/appointments/edit/${appointmentId}`);
  };

  const showSnackBar = (message: string, color: 'success' | 'error' = 'success') => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Routes>
          <Route
            path="/"
            element={
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography level="h1">Appointments</Typography>
                  <Button onClick={handleCreateAppointment}>Create Appointment</Button>
                </Box>
                <Box sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 'sm', p: 2 }}>
                  <AppointmentList onEdit={handleEditAppointment} showSnackBar={showSnackBar} />
                </Box>
              </Box>
            }
          />
          <Route
            path="new"
            element={<AppointmentForm onClose={() => navigate('/appointments')} appointmentId={null} showSnackBar={showSnackBar} />}
          />
          <Route
            path="edit/:appointmentId"
            element={<AppointmentFormWrapper onClose={() => navigate('/appointments')} showSnackBar={showSnackBar} />}
          />
        </Routes>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        color={snackbarColor}
      />
    </React.Fragment>
  );
};

const AppointmentFormWrapper: React.FC<{ onClose: () => void; showSnackBar: (message: string, color?: 'success' | 'error') => void }> = ({ onClose, showSnackBar }) => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  return <AppointmentForm onClose={onClose} appointmentId={appointmentId ? parseInt(appointmentId) : null} showSnackBar={showSnackBar} />;
};

export default Appointment;
