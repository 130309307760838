import axios, { AxiosInstance } from 'axios';

const getBaseUrl = () => {
  const envDomain = process.env.REACT_APP_BASE_URL;
  return envDomain || `${window.location.origin}`;
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  headers: {
    'X-Client': 'OBZ-DOC-APP',
    // 'auth-token': `${process.env.REACT_APP_AUTH_TOKEN}`,
  },
});

const handleAxiosError = (error: any) => {
  if (axios.isAxiosError(error)) {
    const statusCode = error.response?.status;
    if (statusCode === 401) {
      redirectToLogin();
    } else {
      console.error(`Error: ${statusCode} - ${error.message}`);
    }
  } else {
    console.error(`Unexpected error: ${error}`);
  }
  throw error;
};

const redirectToLogin = () => {  
  //window.location.href = '/login';
};

export const fetchData = async (endpoint: string) => {
  try {
    const response = await axiosInstance.get(`${endpoint}`, {
      data: {}
    });
    return response.data;
  } catch (error: any) {
    handleAxiosError(error);
  }
};

export const updateData = async (endpoint: string, data: any) => {
  try {
    const response = await axiosInstance.put(`${endpoint}`, data);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const saveData = async (endpoint: string, data: any) => {
  try {
    const response = await axiosInstance.post(`${endpoint}`, data);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const deleteDate = async (endpoint: string) => {
  try {
    const response = await axiosInstance.delete(`${endpoint}`, {
      data: {}
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
