import React from 'react';
import { IPatient } from '../../../service/Consultation';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import CardActions from '@mui/joy/CardActions';

interface PatientSearchResultProps {
  onClose: () => void;
  addNewPatient: () => void;
  onSelect: (patient: IPatient) => void;
  patients: IPatient[];
}

const PatientSearchResult: React.FC<PatientSearchResultProps> = ({ onClose,addNewPatient, onSelect, patients }) => {
  return (
    <Modal open onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ width: { xs: '100%', md: '50%' }, p: 3, borderRadius: 'md', boxShadow: 'lg', maxHeight: '90vh', overflow: 'auto' }}>
        <Typography level="h4" component="h2" mb={2}>
          Patient Search Results
        </Typography>
        <Divider sx={{ my: 2 }} />
        {patients.length > 0 ? (
          <Sheet
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 'sm',
              overflow: 'auto',
              mt: 2,
              maxHeight: 300,
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
                overflow: 'auto',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 200, padding: '12px 6px' }}>Name</th>
                  <th style={{ width: 150, padding: '12px 6px' }}>Mobile Number</th>
                  <th style={{ width: 100, padding: '12px 6px' }}>Age</th>
                  <th style={{ width: 100, padding: '12px 6px' }}>Sex</th>
                  <th style={{ width: 120, padding: '12px 6px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient) => (
                  <tr key={patient.id}>
                    <td>{patient.name}</td>
                    <td>{patient.mobileNumber}</td>
                    <td>{patient.ageYear} Y , {patient.ageMonth} M</td>
                    <td>{patient.sex}</td>
                    <td>
                      <Link level="body-xs" component="button" onClick={() => onSelect(patient)}>
                        Select
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        ) : (
          <Typography level="body-md">No patients found</Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={addNewPatient}>
            Add New Patient
          </Button>
          <Button variant="solid" onClick={onClose}>
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default PatientSearchResult;
