import { IConsultation } from './Consultation';
import dayjs  from 'dayjs';
import { fetchData, saveData, updateData,deleteDate } from "../middleware/api";

const API_URL = '/api/appointments';

export const getAppointments = async (): Promise<IConsultation[]> => {
  const response = await fetchData('/api/appointments/doctor');
  return response.payload;
};

export const getAppointment = async (id: number): Promise<IConsultation> => {
  const response = await fetchData(`/api/appointments/${id}`);
  return response.payload;
};

export const createAppointment = async (appointment: IConsultation): Promise<IConsultation> => {
  const response = await saveData('/api/appointments/', appointment);
  return response.data;
};

export const updateAppointment = async (id: number, appointment: IConsultation): Promise<IConsultation> => {
  const response = await updateData(`/api/appointments/${id}`, appointment);
  return response.data;
};

export const updateConsultation = async (id: number, appointment: IConsultation): Promise<IConsultation> => {
    const response = await updateData(`/api/consultations/${id}`, appointment);
    return response.data;
  };

export const deleteAppointment = async (id: number): Promise<void> => {
  await deleteDate(`/api/appointments/${id}`);
};

export const searchPatientsByMobile = async (mobileNumber: string) => {
    const response = await fetchData(`/api/patients/search?mobileNumber=${mobileNumber}`);
    return response.payload;
};

export const getPatientHistory = async (id: number | null | undefined): Promise<IConsultation[]> => {    
    const response = await fetchData(`/api/patients/${id}/consultations`);
    return response.payload;
  };
  



export const calculateAge = (dateOfBirth: string): { age: string, ageYear: number, ageMonth: number } => {
    const date = dayjs(dateOfBirth);
    const today = dayjs();
    const years = today.diff(date, 'year');
    const months = today.diff(date, 'month') % 12;
    const days = today.diff(date, 'day') % 30;
    return {
      age: `${years} years, ${months} months, ${days} days`,
      ageYear: years,
      ageMonth: months,
    };
  };
  
  export const initialConsultation: IConsultation = {
    id: 0,
    tokenNumber: 0,
    doctorName: '',
    patient: {
      id: 0,
      displayId: '',
      name: '',
      mobileNumber: '',
      sex: '',
      age: '',
      ageYear: 0,
      ageMonth: 0,
      dateOfBirth: '',
      email: '',
      city: '',
      totalAppointments: 0,
      lastAppointmentDate: '',
    },
    template: {
      id: 0,
      name: '',
      internalTemplateId: '',
      diagnosis: ''
    },
    diagnoseName: '',
    vitalInformation: {
      id: 0,
      height: '',
      weight: '',
      temperature: '',
      temperatureValue: '',
      temperatureUnit: '',
      heartRate: '',
      oxygenSaturation: '',
      respiratoryRate: '',
      headCircumference: '',
      bp: '',      
      crt: '',
      complaints: [],
      otherComplaints: '',
      complaintDuration: '',
      remarks: '',
    },
    appointmentDate: '',
    investigations: [],
    advices: '',
    remarks: '',
    completedDate: new Date(),
    generalInformation: '',
    rs: '',
    cvs: '',
    abdomen: '',
    cns: '',
    fees: 0,
    paymentMode: '',
    nextVisitDate: '',
    drugs: [],
    complaints: [],
    status: '',
    isEditable: false,
  };



  export const initialAppointment: IConsultation = {    
    id:0,    
    doctorName: '',
    patient: {      
        displayId: '',
        name: '',
        mobileNumber: '',
        sex: '',
        age: '',
        ageYear: 0,
        ageMonth: 0,
        dateOfBirth: '',
        email: '',
        city: ''
    },
    vitalInformation: {      
        height: '',
        weight: '',
        temperature: '',
        temperatureValue: '',
        temperatureUnit: '',
        heartRate: '',
        oxygenSaturation: '',
        respiratoryRate: '',
        headCircumference: '',
        bp: '',      
        crt: '',        
        otherComplaints: '',
        complaintDuration: '',
        complaints: [],
        remarks: '',
    },
    appointmentDate: '',
    investigations: [],
    advices: '',
    remarks: '',    
    fees: 0,
    paymentMode: '',            
    status: '',    
    completedDate: new Date(),
    generalInformation: '',
    nextVisitDate: '',
    complaints: [],
};

export const getAdditionalInfoOptions = (type: string) => {
    const options: { [key: string]: { [key: string]: string[] } } = {
      Cough: {
        Nature: ['Dry', 'Wet'],
        Frequency: ['Often', 'Occasional'],
        Variation: ['More at Day', 'More at Night']
      },
      Fever: {
        Grade: ['Low', 'Moderate', 'High'],
        Nature: ['Continuous', 'Intermittent'],
        Associated: ['Chills', 'Rigor']
      },
      LooseStools: {
        Nature: ['Watery', 'Semi Solid'],
        Color: ['Greenish', 'With Blood']
      },
    };
  
    const defaultInfo: { [key: string]: string } = {};
    Object.keys(options[type] || {}).forEach((key) => {
      defaultInfo[key] = '';
    });
    
    return defaultInfo;
  };
  