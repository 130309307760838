import React, { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Textarea from '@mui/joy/Textarea';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Link } from '@mui/joy';
import { IComplaint } from '../../../service/Consultation';
import SelectGroupedOptions from './SelectGroupedOptions';
import Box from '@mui/joy/Box';

interface ConsultationComplaintsProps {
  complaints: IComplaint[];
  onAddComplaint: (complaint: IComplaint) => void;
  onUpdateComplaint: (complaint: IComplaint) => void;
  onDeleteComplaint: (complaint: IComplaint) => void;
}

const complaintOptions = [
  "Cold", "Cough", "Fever", "Loose Stools", "Vomiting", "Abdominal Pain", "History", "Vaccination", "Others"
];

const ConsultationComplaints: React.FC<ConsultationComplaintsProps> = ({ complaints, onAddComplaint, onUpdateComplaint, onDeleteComplaint }) => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentComplaintId, setCurrentComplaintId] = useState<number | null>(null);
  const [newComplaintType, setNewComplaintType] = useState<string[]>([]);
  const [newComplaintDetails, setNewComplaintDetails] = useState(''); // For custom complaint details
  const [newComplaintDuration, setNewComplaintDuration] = useState('');
  const [newComplaintRemarks, setNewComplaintRemarks] = useState('');
  const [newAdditionalInfo, setNewAdditionalInfo] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (editMode && currentComplaintId !== null) {
      const complaint = complaints.find(c => c.id === currentComplaintId);
      if (complaint) {
        const complaintType = complaintOptions.includes(complaint.type) ? complaint.type : "Others";
        setNewComplaintType([complaintType]);
        setNewComplaintDetails(complaintType === "Others" ? complaint.type : ''); // Set custom details if type is "Others"
        setNewComplaintDuration(complaint.duration.toString());
        setNewComplaintRemarks(complaint.remarks || '');
        setNewAdditionalInfo(complaint.additionalInfo || {});
      }
    }
  }, [editMode, currentComplaintId, complaints]);

  const handleOpenModal = (complaint?: IComplaint) => {
    if (complaint) {
      setCurrentComplaintId(complaint.id);
      setEditMode(true);
      const complaintType = complaintOptions.includes(complaint.type) ? complaint.type : "Others";
      setNewComplaintType([complaintType]);
      setNewComplaintDetails(complaintType === "Others" ? complaint.type : ''); // Set custom details if type is "Others"
      setNewComplaintDuration(complaint.duration + '');
      setNewComplaintRemarks(complaint.remarks || '');
      setNewAdditionalInfo(complaint.additionalInfo);
    } else {
      setCurrentComplaintId(null);
      setNewComplaintType([]);
      setNewComplaintDetails('');
      setNewComplaintDuration('');
      setNewComplaintRemarks('');
      setNewAdditionalInfo({});
      setEditMode(false);
    }
    setOpen(true);
  };

  const handleAddOrUpdateComplaint = () => {
    newComplaintType.forEach((type) => {
      const complaintType = type === "Others" ? newComplaintDetails : type;
      const complaintData: IComplaint = {
        id: editMode && currentComplaintId ? currentComplaintId : null,
        type: complaintType,
        duration: Number(newComplaintDuration),
        additionalInfo: newAdditionalInfo,
        remarks: newComplaintRemarks
      };

      if (editMode) {
        onUpdateComplaint(complaintData);
      } else {
        onAddComplaint(complaintData);
      }
    });

    setOpen(false);
    setNewComplaintType([]);
    setNewComplaintDetails('');
    setNewComplaintDuration('');
    setNewComplaintRemarks('');
    setNewAdditionalInfo({});
  };

  const availableComplaintOptions = editMode
    ? complaintOptions // Include all options in edit mode
    : complaintOptions.filter(option => !complaints.some(complaint => complaint.type === option) || option === "Others");

  const shouldShowAdditionalInfo = (type: string) => {
    return ["Cough", "Fever", "Loose Stools"].includes(type);
  };

  return (
    <div>
      <Typography level="h4" component="h3" mb={2}>Complaints</Typography>
      <Sheet
        className="ComplaintsTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
            overflow: 'auto', // Enable scrolling within the table
          }}
        >
          <thead>
            <tr>
              <th style={{ width: '20%', padding: '12px 6px' }}>Complaint Type</th>
              <th style={{ width: '15%', padding: '12px 6px' }}>Duration (Days)</th>
              <th style={{ width: '25%', padding: '12px 6px' }}>Additional Info</th>
              <th style={{ width: '30%', padding: '12px 6px' }}>Remarks</th>
              <th style={{ width: '10%', padding: '12px 6px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {complaints.map((complaint) => (
              <tr key={complaint.type}>
                <td>
                  <Typography level="body-xs">{complaint.type}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{complaint.duration || 'N/A'}</Typography>
                </td>
                <td>
                  {shouldShowAdditionalInfo(complaint.type) ? (
                    <SelectGroupedOptions
                      type={complaint.type}
                      value={Object.entries(complaint.additionalInfo).map(([group, option]) => `${group}: ${option}`)}
                      onChange={(newValues) => {
                        const updatedAdditionalInfo = newValues.reduce<{ [key: string]: string }>((acc, value) => {
                          const [group, option] = value.split(': ');
                          if (group && option) {
                            acc[group] = option;
                          }
                          return acc;
                        }, {});
                        onUpdateComplaint({
                          ...complaint,
                          additionalInfo: updatedAdditionalInfo
                        });
                      }}
                      multiple
                    />
                  ) : (
                    <Typography level="body-xs">{complaint.additionalInfoValues || 'N/A'}</Typography>
                  )}
                </td>
                <td>
                  <Typography level="body-xs">{complaint.remarks || 'N/A'}</Typography>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Link level="body-xs" component="button" startDecorator={<EditRoundedIcon />} onClick={() => handleOpenModal(complaint)}>
                      Edit
                    </Link>
                    <Link level="body-xs" component="button" startDecorator={<DeleteRoundedIcon />} color="danger" onClick={() => onDeleteComplaint(complaint)}>
                      Delete
                    </Link>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Button
        size="sm"
        variant="solid"
        onClick={() => handleOpenModal()}
        sx={{ mt: 2 }}
      >
        Add Complaint
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ width: { xs: '100%', md: '50%' }, p: 3, borderRadius: 'md', boxShadow: 'lg', maxHeight: '90vh', overflow: 'auto' }}>
          <ModalClose />
          <Typography level="h4" component="h2">
            {editMode ? 'Edit Complaint' : 'Add New Complaint'}
          </Typography>
          <FormControl sx={{ mt: 2 }}>
            <FormLabel>Complaint Type</FormLabel>
            <Select
                size="sm"
                placeholder="Select Complaint Type"
                value={newComplaintType[0] || ''}  // Use the first element of the array or an empty string
                onChange={(e, newValue) => {
                    setNewComplaintType([newValue as string]);  // Store the selected value as an array
                    if (newValue === "Others") {
                    setNewComplaintDetails('');  // Reset custom complaint details when "Others" is selected
                    }
                }}
                disabled={editMode}
                >
                {availableComplaintOptions.map(option => (
                    <Option key={option} value={option}>
                    {option}
                    </Option>
                ))}
                </Select>
          </FormControl>
          
          {newComplaintType.includes("Others") && (
            <FormControl sx={{ mt: 2 }}>
              <FormLabel>Complaint Details</FormLabel>
              <Input
                size="sm"
                placeholder="Specify the complaint"
                value={newComplaintDetails}
                disabled={editMode}
                onChange={(e) => setNewComplaintDetails(e.target.value)}
              />
            </FormControl>
          )}

          <FormControl sx={{ mt: 2 }}>
            <FormLabel>Duration (Days)</FormLabel>
            <Input
              size="sm"
              placeholder="Duration of Illness"
              value={newComplaintDuration}
              onChange={(e) => setNewComplaintDuration(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <FormLabel>Remarks</FormLabel>
            <Textarea
              size="sm"
              minRows={3}
              placeholder="Remarks"
              value={newComplaintRemarks}
              onChange={(e) => setNewComplaintRemarks(e.target.value)}
            />
          </FormControl>
          <Button
            size="sm"
            variant="solid"
            onClick={handleAddOrUpdateComplaint}
            sx={{ mt: 2 }}
          >
            {editMode ? 'Update' : 'Add'}
          </Button>
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default ConsultationComplaints;
