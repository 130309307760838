
import React from 'react';
import Select from '@mui/joy/Select';
import Option, { optionClasses } from '@mui/joy/Option';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListItemDecorator, { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';

interface SelectGroupedOptionsProps {
  type: string;
  value: string[];
  onChange: (newValue: string[]) => void;
  multiple?: boolean;
}

const additionalInfoOptions: { [key: string]: { group: string; options: string[] }[] } = {
  Cough: [
    { group: "Nature", options: ["Dry", "Wet"] },
    { group: "Frequency", options: ["Often", "Occasional"] },
    { group: "Variation", options: ["More at Day", "More at Night"] },
  ],
  Fever: [
    { group: "Grade", options: ["Low", "Moderate", "High"] },
    { group: "Nature", options: ["Continuous", "Intermittent"] },
    { group: "Associated", options: ["Chills", "Rigor"] },
  ],
  "Loose Stools": [
    { group: "Nature", options: ["Watery", "Semi Solid"] },
    { group: "Color", options: ["Greenish", "With Blood"] },
  ],
};

const SelectGroupedOptions: React.FC<SelectGroupedOptionsProps> = ({ type, value, onChange, multiple }) => {
  const options = additionalInfoOptions[type] || [];

  return (
    <Select
      placeholder="Choose additional info"
      value={value}
      onChange={(_, newValue) => onChange(newValue as string[])}
      multiple={multiple}
      sx={{ width: '100%' }}
    >
      {options.map(({ group, options }, index) => (
        <React.Fragment key={group}>
          {index !== 0 && <ListDivider role="none" />}
          <List
            aria-labelledby={`select-group-${group}`}
            sx={{ '--ListItemDecorator-size': '28px' }}
          >
            <ListItem id={`select-group-${group}`} sticky>
              <Typography level="body-xs" textTransform="uppercase">
                {group} ({options.length})
              </Typography>
            </ListItem>
            {options.map((option) => (
              <Option
                key={option}
                value={`${group}: ${option}`}
                sx={{
                  [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]: {
                    opacity: 1,
                  },
                }}
              >
                <ListItemDecorator sx={{ opacity: 0 }}>
                  <Check />
                </ListItemDecorator>
                {option}
              </Option>
            ))}
          </List>
        </React.Fragment>
      ))}
    </Select>
  );
};

export default SelectGroupedOptions;