import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/joy/Divider';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Autocomplete from '@mui/joy/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getTemplates, getTemplate } from '../../../service/TemplateService';
import { getAppointment, updateConsultation, initialConsultation, calculateAge } from '../../../service/ConsultationService';
import { IConsultation, IConsultationDrug } from '../../../service/Consultation';
import { ITemplate } from '../../../service/TemplateService';
import { CardActions, FormControl, FormLabel, Input, Textarea } from '@mui/joy';
import dayjs, { Dayjs } from 'dayjs';
import PatientInformation from './PatientInformation';
import VitalInformation from './VitalInformation';
import ConsultationComplaints from './ConsultationComplaints';
import ConsultationDrug from './ConsultationDrug';
import ConsultationDrugForm from './ConsultationDrugForm';
import { useAppContext } from '../../../contexts/AppContext';
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import ConsultationClinicalFindings from './ConsultationClinicalFindings';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import { generatePDF } from '../../../service/PDFService';


const investigationOptions = [
  "CBC, CRP",
  "Urine RE",
  "X-ray Chest AP",
  "X-ray Chest PA",
  "SGOT / SGPT",
  "Peripheral Smear",
  "USG ABD & KUB"
];

interface ConsultationFormProps {
  onClose: () => void;
  consultationId: number | null;
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const ConsultationForm: React.FC<ConsultationFormProps> = ({ onClose, consultationId, showSnackBar }) => {
  const materialTheme = materialExtendTheme();
  const [consultation, setConsultation] = useState<IConsultation>(initialConsultation);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(true); // Set initial loading to true
  const { clinic, doctor } = useAppContext();
  const [openDrugModal, setOpenDrugModal] = useState<boolean>(false);
  const [editDrug, setEditDrug] = useState<IConsultationDrug | null>(null);
  const [clinicalFindings, setClinicalFindings] = useState({
    RS: ['B/L Air Entry Equal'] as string[],
    CSV: ["S1S2+"] as string[],
    Abdoman: ["Soft","BS+"] as string[],
    CNS: ["No FND"] as string[],
    GeneralExaminations: ['Alert / Active'] as string[],
  }); 
  const [openPrintModal, setOpenPrintModal] = useState<boolean>(false);

  useEffect(() => {
    if (consultationId) {
      getAppointment(consultationId)
        .then((data) => {
          if (data.patient.dateOfBirth) {
            const { age, ageYear, ageMonth } = calculateAge(data.patient.dateOfBirth);
            data.patient.age = age;
            data.patient.ageYear = ageYear;
            data.patient.ageMonth = ageMonth;
          }

  
          // Initialize clinical findings state with existing values
          setClinicalFindings({
            RS: data.rs ? data.rs.split(', ') : [],
            CSV: data.cvs ? data.cvs.split(', ') : [] ,
            Abdoman: data.abdomen ? data.abdomen.split(', ') : [] ,
            CNS: data.cns ? data.cns.split(', ') : [],
            GeneralExaminations: data.generalInformation ? data.generalInformation.split(', ') : []
          }); 

  
          setConsultation(data);
  
          // Automatically select the template if it exists in the response
          if (data.template?.id) {
            setSelectedTemplateId(data.template.id);
          }
  
        })
        .catch((error) => {
          console.error('Error fetching consultation:', error);
          showSnackBar('Failed to load consultation.', 'error');
        })
        .finally(() => {
          setLoading(false); // Set loading to false after data is fetched
        });
    } else {
      setLoading(false); // Set loading to false if creating a new consultation
    }
    fetchTemplates();
  }, [consultationId]);

  const fetchTemplates = async () => {
    try {
      const templates = await getTemplates();
      setTemplates(templates);
    } catch (error) {
      showSnackBar('Error fetching templates', 'error');
    }
  };

  const handleFindingsChange = (name: string, value: string[]) => {
    setClinicalFindings((prevFindings) => ({
      ...prevFindings,
      [name]: value,
    }));
  
    const mappedValues = value.join(', ');
  
    setConsultation((prevConsultation) => {
      switch (name) {
        case 'RS':
          return { ...prevConsultation, rs: mappedValues };
        case 'CSV':
          return { ...prevConsultation, cvs: mappedValues };
        case 'Abdoman':
          return { ...prevConsultation, abdomen: mappedValues };
        case 'CNS':
          return { ...prevConsultation, cns: mappedValues };
        case 'GeneralExaminations':
          return { ...prevConsultation, generalInformation: mappedValues };
        default:
          return prevConsultation;
      }
    });
  };

  const handleTemplateChange = async (templateId: number | null) => {
    setSelectedTemplateId(templateId);
    if (templateId) {
      try {
        const template = await getTemplate(templateId);
        setConsultation((prevConsultation) => ({
          ...prevConsultation,
          template: {
            id: template.id,
            name: template.name,
            internalTemplateId: template.internalTemplateId,
            diagnosis: template.diagnosis
          },
          drugs: template.drugs.map((drug) => ({
            ...drug,
            id: Date.now() + Math.random(), // Generating a unique ID
            dosageValue: drug.dosageValue || '', // Ensure dosageValue is a string
          })),
          investigations: template.investigations || [],
          advices: template.advices || '',
          remarks: template.remarks || '',
        }));
      } catch (error) {
        showSnackBar('Error fetching template', 'error');
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => {
    const name = e.target?.name || e.name;
    const val = e.target?.value ?? value;

    setConsultation((prevConsultation) => {
      const keys = name.split('.');
      if (keys.length === 2) {
        const [mainKey, subKey] = keys as [keyof IConsultation, string];
        return {
          ...prevConsultation,
          [mainKey]: {
            ...(prevConsultation[mainKey] as any),
            [subKey]: val,
          },
        };
      } else {
        return {
          ...prevConsultation,
          [name]: val,
        };
      }
    });

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSave = async () => {
    const newError: { [key: string]: string } = {};

    setErrors(newError);

    if (Object.values(newError).some((error) => error)) {
      showSnackBar('Please fill in all required fields.' + JSON.stringify(newError), 'error');
      return;
    }

    setLoading(true);
    try {
      const consultationData = {
        ...consultation,
        clinicId: clinic?.id,
        doctorId: doctor?.id,
      };

      if (consultationId) {
        await updateConsultation(consultationId, consultationData);
        showSnackBar('Consultation Updated Successfully!!!', 'success');
        console.log("status === " + consultationData.status)
        if ( consultationData.status == 'APPOINTMENT_CREATED') {
            setOpenPrintModal(true)
        }
      }
    } catch (error: any) {
      showSnackBar(error.response?.data?.message || 'Error saving consultation.', 'error');
      console.log('Error saving data:', JSON.stringify(error));
    } finally {
      setLoading(false);
      //onClose();
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    setConsultation((prevConsultation) => ({
      ...prevConsultation,
      nextVisitDate: date ? date.toISOString() : undefined,
    }));
  };

  const handleAddDrug = () => {
    setEditDrug(null);
    setOpenDrugModal(true);
  };

  const handleEditDrug = (drug: IConsultationDrug) => {
    setEditDrug(drug);
    setOpenDrugModal(true);
  };

  const handleDeleteDrug = (tradeName: string) => {
    setConsultation((prevConsultation) => ({
      ...prevConsultation,
      drugs: prevConsultation.drugs?.filter((drug) => drug.tradeName !== tradeName) || [],
    }));
  };

  const handleDrugSave = (drug: IConsultationDrug) => {
    setConsultation((prevConsultation) => {
      const drugs = editDrug
        ? prevConsultation.drugs?.map((d) => (d.tradeName === editDrug.tradeName ? drug : d)) || []
        : [...(prevConsultation.drugs || []), drug];
      return { ...prevConsultation, drugs };
    });
    setOpenDrugModal(false);
  };


  const handleInvestigationChange = (event: any, newValue: string[]) => {
    setConsultation((prevConsultation) => ({
      ...prevConsultation,
      investigations: newValue,
    }));
  };

  const handlePrintOptionClick = async (reportType: string) => {
    if ( clinic && doctor ) {
        generatePDF(consultation, clinic, doctor, reportType);
    }    
    setOpenPrintModal(false);
};
  
  

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
          <CircularProgress /> {/* Show a loading spinner */}
        </Box>
      ) : (
        <Card
          sx={{
            width: { xs: '100%', md: '75%' },
            p: 3,
            borderRadius: 'md',
            boxShadow: 'lg',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <Typography level="h4" component="h2" mb={2}>
            {consultationId ? 'Edit Consultation' : 'Consultation'}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Patient Information Section */}
          <PatientInformation consultation={consultation} showSnackBar={showSnackBar} />

          <Divider sx={{ my: 2 }} />

          {/* Consultation Complaints Section */}
          <ConsultationComplaints
            complaints={consultation.complaints}
            onAddComplaint={(complaint) =>
              setConsultation((prevConsultation) => ({
                ...prevConsultation,
                complaints: [...prevConsultation.complaints, complaint],
              }))
            }
            onUpdateComplaint={(updatedComplaint) =>
              setConsultation((prevConsultation) => ({
                ...prevConsultation,
                complaints: prevConsultation.complaints.map((complaint) =>
                  complaint.type === updatedComplaint.type ? updatedComplaint : complaint
                ),
              }))
            }
            onDeleteComplaint={(deleteComplaint) =>
              setConsultation((prevConsultation) => ({
                ...prevConsultation,
                complaints: prevConsultation.complaints.filter(
                  (complaint) => complaint.type !== deleteComplaint.type
                ),
              }))
            }
            />
            
  
            <Divider sx={{ my: 2 }} />
  
            {/* Vital Information Section */}
            <VitalInformation
              consultation={consultation}
              handleChange={handleChange}
              handleSelectChange={handleChange}
              errors={errors}
            />
  
            <Divider sx={{ my: 2 }} />
  
            {/* Consultation Clinical Findings Section */}
            <ConsultationClinicalFindings
              findings={clinicalFindings}
              onFindingsChange={handleFindingsChange}
            />
  
            <Divider sx={{ my: 2 }} />
          
            {/* Treatment Section */}
            <Box
              sx={{
                p: 2,
                borderRadius: 'md',
                backgroundColor: 'background.level2',
              }}
            >
              <Typography level="h4" component="h3" mb={2}>
                Treatment
              </Typography>
  
              {/* Template Selection and Diagnosis Section */}
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel>(Diagnosis) Template</FormLabel>
                  <Select
                    value={selectedTemplateId ? selectedTemplateId.toString() : ''}
                    onChange={(e, newValue) => handleTemplateChange(newValue ? parseInt(newValue) : null)}
                    placeholder="Select a template"
                    size="sm"
                  >
                    {templates.map((template) => (
                      <Option key={template.id} value={template.id.toString()}>
                        {template.name}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel>Diagnosis</FormLabel>
                  <Input
                    size="sm"
                    placeholder="Diagnosis"
                    name="diagnoseName"
                    value={consultation.diagnoseName || ''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>
  
              {/* Investigations Section */}
              <FormControl sx={{ my: 2 }}>
                <FormLabel>Investigations</FormLabel>
                <Autocomplete
                  multiple
                  options={investigationOptions}
                  value={consultation.investigations || []}
                  onChange={handleInvestigationChange}
                  isOptionEqualToValue={(option, value) => option === value}
                  sx={{ flex: 1 }}
                />
              </FormControl>
  
              {/* Consultation Drug Section */}
              <ConsultationDrug
                drugs={consultation.drugs || []}
                onAddDrug={handleAddDrug}
                onEditDrug={handleEditDrug}
                onDeleteDrug={handleDeleteDrug}
              />
            </Box>
  
            <Divider sx={{ my: 2 }} />
  
            {/* Advice and Remarks Section */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>Advice</FormLabel>
                <Textarea
                  size="sm"
                  placeholder="Advice"
                  name="advices"
                  value={consultation.advices || ''}
                  onChange={handleChange}
                  minRows={3}
                />
              </FormControl>
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>Remarks</FormLabel>
                <Textarea
                  size="sm"
                  placeholder="Remarks"
                  name="remarks"
                  value={consultation.remarks || ''}
                  onChange={handleChange}
                  minRows={3}
                />
              </FormControl>
            </Box>
  
            <Divider sx={{ my: 2 }} />
  
            {/* Next Visit Date Section */}
            <FormControl sx={{ width: '100%' }}>
              <Box component="label" sx={{ display: 'block' }}>
                <FormLabel htmlFor="consultation-next-visit">Next Visit Date</FormLabel>
                <MaterialCssVarsProvider
                  theme={{ [MATERIAL_THEME_ID]: materialTheme }}
                  defaultMode="system"
                >
                  <JoyCssVarsProvider defaultMode="system">
                    <CssBaseline enableColorScheme />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={consultation.nextVisitDate ? dayjs(consultation.nextVisitDate) : null}
                        onChange={handleDateChange}
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { size: 'small', id: 'consultation-next-visit' } }}
                      />
                    </LocalizationProvider>
                  </JoyCssVarsProvider>
                </MaterialCssVarsProvider>
              </Box>
            </FormControl>
  
            <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              {/* Print Report Button */}
              {consultation.status === 'CONSULTATION_COMPLETED' && (
                <Button
                  size="sm"
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenPrintModal(true)}
                  sx={{ mr: 'auto' }}
                >
                  Print Report
                </Button>
              )}
  
              <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
                Cancel
              </Button>
              <Button
                size="sm"
                variant="solid"
                onClick={handleSave}
                disabled={loading}
                startDecorator={loading && <CircularProgress size={16} />}
                sx={{ ml: 1 }}
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </CardActions>
          </Card>
        )}
        
        {openDrugModal && (
          <ConsultationDrugForm onClose={() => setOpenDrugModal(false)} onSave={handleDrugSave} drug={editDrug} />
        )}
  
        {/* Print Modal */}
        <Modal open={openPrintModal} onClose={() => setOpenPrintModal(false)}>
          <ModalDialog
            aria-labelledby="print-options-modal"
            sx={{
              borderRadius: 'md',
              boxShadow: 'lg',
              p: 3,
              minWidth: 300,
            }}
          >
            <ModalClose />
            <Typography id="print-options-modal" level="h4" component="h2" mb={2} textAlign="center">
              Select Print Option
            </Typography>
            <List sx={{ gap: 1 }}>
              <ListItem>
                <ListItemButton
                  onClick={() => handlePrintOptionClick('FULL')}
                  sx={{
                    borderRadius: 'md',
                    border: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                    '&:hover': {
                      borderColor: 'primary.main',
                      backgroundColor: 'primary.light',
                    },
                    p: 2,
                  }}
                >
                  Full Prescription Report
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => handlePrintOptionClick('VITAL-TREAT')}
                  sx={{
                    borderRadius: 'md',
                    border: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                    '&:hover': {
                      borderColor: 'primary.main',
                      backgroundColor: 'primary.light',
                    },
                    p: 2,
                  }}
                >
                  Vital Diagnosis Report
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => handlePrintOptionClick('DIAG-TREAT')}
                  sx={{
                    borderRadius: 'md',
                    border: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                    '&:hover': {
                      borderColor: 'primary.main',
                      backgroundColor: 'primary.light',
                    },
                    p: 2,
                  }}
                >
                  Diagnosis Treatment Report
                </ListItemButton>
              </ListItem>
            </List>
          </ModalDialog>
        </Modal>
      </Box>
    );
  };
  
  export default ConsultationForm;
  