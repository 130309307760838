import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Autocomplete from '@mui/joy/Autocomplete';
import { IDrug, getDrug, saveDrug, updateDrug } from '../../../service/DrugService';
import CardActions from '@mui/joy/CardActions';
import Grid from '@mui/joy/Grid';
import Divider from '@mui/joy/Divider';

interface DrugFormProps {
  onClose: () => void;
  drugId: number | null;
  showSnackBar: (message: string, color?: 'success' | 'error') => void;
}

const drugTypes = [
  "DROPS", "SYRUP", "TABLET", "SACHET", "CREAM", "OINTMENT", "LOTION", "EYE DROPS", "DPOWDER", 
  "POWDER", "DUSTING POWDER", "GEL", "SPRAY", "CAPSULE", "INJECTION", "NASAL DROPS", "NASAL SPRAY", 
  "MDI", "VACCINE", "RESPULES", "FLUIDS", "MISC", "EAR DROPS", "SOAP", "PROTEIN POWDER", "POWDER SUSPENSIONS"
];

const dosageUnits = ["ML", "Drops", "Local Application", "Eye Local Application", "NOS"];
const frequencies = ["2nd-Hourly", "4th-Hourly", "6th-Hourly", "8th-Hourly", "12th-Hourly", "Morning", "Afternoon", "Evening", "Night", "SOS-/-If needed", "Once-a-Day", "Twice-a-Day", "Thrice-a-Day", "As-Advised"];
const instructions = ["Before-Food", "After-Food", "Both-Eye", "Left-Eye", "Right-Eye", "Both-Ear", "Left-Ear", "Right-Ear", "Nasal"];
const durations = ["DAYS", "WEEKS", "MONTHS"];
const statuses = ["Active", "Inactive"];

const DrugForm: React.FC<DrugFormProps> = ({ onClose, drugId, showSnackBar }) => {
  const [drug, setDrug] = useState<IDrug>({
    id: 0,
    tradeName: '',
    genericName: '',
    type: '',
    weight: '0',
    frequency: [],
    instruction: '',
    dosageUnit: '',
    duration: 0,
    durationUnit: '',
    updatedBy: 0,
    createdAt: '',
    updatedAt: '',
    active: true,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(true); // Set initial loading to true

  useEffect(() => {
    if (drugId) {        
      setLoading(true);
      getDrug(drugId).then((data) => {
        setDrug(data);
      }).catch(() => {
        showSnackBar('Failed to load drug data.', 'error');
      }).finally(() => {
        setLoading(false); // Stop loading after data is fetched
      });
    } else {
      setLoading(false); // Stop loading if creating a new drug
    }
  }, [drugId, showSnackBar]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any, value?: any) => {
    const name = e.target?.name || e.name;
    const val = e.target?.value ?? value;
  
    setDrug((prevDrug) => ({
      ...prevDrug,
      [name]: name === 'active' ? val === 'Active' : val,
    }));
  
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };
  
  const handleSelectChange = (name: string, value: string | string[]) => {
    setDrug((prevDrug) => ({
      ...prevDrug,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const handleSave = async () => {
    const newError = {
      tradeName: !drug.tradeName ? 'Trade Name is required' : '',
      genericName: !drug.genericName ? 'Generic Name is required' : '',
      type: !drug.type ? 'Drug Type is required' : '',
      weight: !drug.weight ? 'Weight is required' : '',
      dosageUnit: !drug.dosageUnit ? 'Dosage Unit is required' : '',
      frequency: drug.frequency.length === 0 ? 'Frequency is required' : '',
      instruction: !drug.instruction ? 'Instruction is required' : '',
      duration: drug.duration === 0 ? 'Duration is required' : '',
      durationUnit: !drug.durationUnit ? 'Duration Unit is required' : '',
    };

    setErrors(newError);

    if (Object.values(newError).some((error) => error)) {
      showSnackBar("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    try {
      if (drugId) {
        await updateDrug(drugId, drug);
        showSnackBar("Drug Updated Successfully!!!");
      } else {
        await saveDrug(drug);
        showSnackBar("Drug Created Successfully!!!");
      }
    } catch (error) {
      console.error('Error saving data:', error);
      showSnackBar('Failed to save drug data.', 'error');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
          <CircularProgress /> {/* Show a loading spinner */}
        </Box>
      ) : (
        <Card sx={{ width: { xs: '100%', md: '75%' }, p: 3, borderRadius: 'md', boxShadow: 'lg', maxHeight: '90vh', overflow: 'auto' }}>
          <Typography level="h4" component="h2" mb={2}>
            {drugId ? 'Edit Drug' : 'Create Drug'}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Drug Type</FormLabel>
                <Autocomplete
                  placeholder="Search by Type"
                  options={drugTypes}
                  value={drug.type || null}
                  onChange={(e, newValue) => handleChange({ name: 'type' }, newValue ?? '')}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  sx={{ flex: 1, borderColor: errors.type ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Trade Name</FormLabel>
                <Input
                  size="sm"
                  placeholder="Trade Name"
                  name="tradeName"
                  value={drug.tradeName}
                  onChange={handleChange}
                  required
                  sx={{ borderColor: errors.tradeName ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Generic Name</FormLabel>
                <Input
                  size="sm"
                  placeholder="Generic Name"
                  name="genericName"
                  value={drug.genericName}
                  onChange={handleChange}
                  required
                  sx={{ borderColor: errors.genericName ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>MG/ KG</FormLabel>
                <Input
                  size="sm"
                  placeholder="MG/ KG"
                  name="weight"
                  value={drug.weight}
                  onChange={handleChange}
                  required
                  sx={{ borderColor: errors.weight ? 'red' : '' }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Dosage Unit</FormLabel>
                <Select
                  name="dosageUnit"
                  value={drug.dosageUnit}
                  onChange={(e, newValue) => handleChange({ name: 'dosageUnit' }, newValue ?? '')}
                  size="sm"
                  required
                  sx={{ borderColor: errors.dosageUnit ? 'red' : '' }}
                >
                  {dosageUnits.map((unit) => (
                    <Option key={unit} value={unit}>
                      {unit}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Frequency</FormLabel>
                <Select
                  multiple
                  name="frequency"
                  value={drug.frequency}
                  onChange={(e, newValue) => handleSelectChange('frequency', newValue as string[])}
                  size="sm"
                  required
                  sx={{ borderColor: errors.frequency ? 'red' : '' }}
                >
                  {frequencies.map((frequency) => (
                    <Option key={frequency} value={frequency}>
                      {frequency}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Instruction</FormLabel>
                <Select
                  name="instruction"
                  value={drug.instruction}
                  onChange={(e, newValue) => handleChange({ name: 'instruction' }, newValue ?? '')}
                  size="sm"
                  required
                  sx={{ borderColor: errors.instruction ? 'red' : '' }}
                >
                  {instructions.map((instruction) => (
                    <Option key={instruction} value={instruction}>
                      {instruction}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Grid>


            <Grid xs={12} sm={6}>
          <FormLabel>Duration</FormLabel>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FormControl sx={{ flex: 1 }}>
              <Input
               size="sm"
               placeholder="Duration"
               name="duration"
               value={drug.duration}
               onChange={handleChange}
               required
               sx={{ borderColor: errors.duration ? 'red' : '' }}
              />    
            </FormControl>
            <FormControl sx={{ flex: 1, maxWidth: '100px' }}>
            <Select
                  name="durationUnit"
                  value={drug.durationUnit}
                  onChange={(e, newValue) => handleChange({ name: 'durationUnit' }, newValue ?? '')}
                  size="sm"
                  required
                  sx={{ borderColor: errors.durationUnit ? 'red' : '' }}
                >
                  {durations.map((duration) => (
                    <Option key={duration} value={duration}>
                      {duration}
                    </Option>
                  ))}
                </Select>
            </FormControl>
          </Box>
        </Grid>



            <Grid xs={12}>
              <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                  size="sm"
                  placeholder="Notes"
                  minRows={3}
                  name="notes"
                  value={drug.notes}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select
                  name="active"
                  value={drug.active ? 'Active' : 'Inactive'}
                  onChange={(e, newValue) => handleChange({ name: 'active' }, newValue)}
                  size="sm"
                  required
                  sx={{ borderColor: errors.active ? 'red' : '' }}
                >
                  {statuses.map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button size="sm" variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              onClick={handleSave}
              disabled={loading}
              startDecorator={loading && <CircularProgress size={16} />}
              sx={{ ml: 1 }}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </CardActions>
        </Card>
      )}
    </Box>
  );
};

export default DrugForm;
