import { fetchData, saveData, updateData } from "../middleware/api";


export interface ITemplateDrug {
    id: number;
    drugId: number;
    type: string;
    tradeName: string;
    genericName: string;
    weight: string;
    dosageValue?: string;
    dosageUnit: string;
    frequency: string[];
    instruction: string;
    duration: number;
    durationUnit: string;
    notes: string;
  }
  
  export interface ITemplate {
    id: number;
    name: string;
    internalTemplateId: string;
    diagnosis: string;
    description: string;
    updatedBy: number;
    doctor_id: number;
    investigations: string[];
    drugs: ITemplateDrug[];
    advices: string;
    remarks: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  }



// Fetch all templates
export const getTemplates = async (): Promise<ITemplate[]> => {
  const endpoint = '/api/templates/';
  try {
    const data = await fetchData(endpoint);
    return data.payload;
  } catch (error) {
    throw new Error('Error fetching templates');
  }
};

// Fetch a single template by ID
export const getTemplate = async (id: number): Promise<ITemplate> => {
  const endpoint = `/api/templates/${id}`;
  try {
    const data = await fetchData(endpoint);
    return data.payload;
  } catch (error) {
    throw new Error('Error fetching template');
  }
};

// Save a new template
export const saveTemplate = async (template: ITemplate): Promise<ITemplate> => {
  const payload = await saveData('/api/templates/', template);
  return payload;
};

// Update an existing template
export const updateTemplate = async (templateId: number, template: ITemplate): Promise<ITemplate> => {
  const payload = await updateData(`/api/templates/${templateId}`, template);
  return payload;
};

// Fetch drugs by type
export const getDrugsByType = async (type: string): Promise<any> => {
    const endpoint = `/api/drugs/filters/type/?drug-type=${type}`;
    try {
      const data = await fetchData(endpoint);
      return data;
    } catch (error) {
      throw new Error('Error fetching drugs by type');
    }
  };